import { environment } from 'src/environments/environment';

// All API_URLS used in the Vestige Admin Panel

export const ServiceEnum = {
  Otp: (environment.API_URLS_ENDPOINT + 'generateotp'/*:ServiceType*/),
  Verifyotp: (environment.API_URLS_ENDPOINT + 'verifyotp'/*:ServiceType*/),
  register: (environment.API_URLS_ENDPOINT + 'register'/*:ServiceType*/),
  getvendor: (environment.API_URLS_ENDPOINT + 'vendordetail'/*:ServiceType*/),
  getvendorcat: (environment.API_URLS_ENDPOINT + 'vendorcategories'/*:ServiceType*/),
  gettutorcat: (environment.API_URLS_ENDPOINT + 'tutorcategories'/*:ServiceType*/),
  getamenities: (environment.API_URLS_ENDPOINT + 'amenities'/*:ServiceType*/),
  getexpertise: (environment.API_URLS_ENDPOINT + 'expertise'/*:ServiceType*/),
  getLanguages: (environment.API_URLS_ENDPOINT + 'get-language'/*:ServiceType*/),
  getTutors: (environment.API_URLS_ENDPOINT + 'tutor_search'/*:ServiceType*/),
  gettutorinfo: (environment.API_URLS_ENDPOINT + 'tutorprofile'/*:ServiceType*/),
  follow: (environment.API_URLS_ENDPOINT + 'follow'/*:ServiceType*/),
  unfollow: (environment.API_URLS_ENDPOINT + 'unfollow'/*:ServiceType*/),
  getbatch: (environment.API_URLS_ENDPOINT + 'getbatch'/*:ServiceType*/),
  getwizpasses: (environment.API_URLS_ENDPOINT + 'getwizpasses'),
  wizpassplan: (environment.API_URLS_ENDPOINT + 'wizpassplan'/*:ServiceType*/),
  getSingleWizPassPan: (environment.API_URLS_ENDPOINT + 'getSingleWizPassPan'/*:ServiceType*/),
  sendfeedback: (environment.API_URLS_ENDPOINT + 'sendfeedback'/*:ServiceType*/),
  getallcourses: (environment.API_URLS_ENDPOINT + 'getallcourses'/*:ServiceType*/),
  fetchAllMessages: (environment.NODE_URLS_ENDPOINT + "/api/" + 'message/fetch_all'/*:ServiceType*/),
  dashboarddetail: (environment.API_URLS_ENDPOINT + 'dashboard'/*:ServiceType*/),
  tutordashboard: (environment.API_URLS_ENDPOINT + 'getdashboarddetail'/*:ServiceType*/),
  gettutorcourse: (environment.API_URLS_ENDPOINT + 'gettutorcourselist'/*:ServiceType*/),
  createChatId: (environment.NODE_URLS_ENDPOINT + "api/conversions/create"/*:ServiceType*/),
  batchlist: (environment.API_URLS_ENDPOINT + "batchlist"/*:ServiceType*/),
  generatecourse: (environment.API_URLS_ENDPOINT + "generatecourse"/*:ServiceType*/),
  gettutorcategory: (environment.API_URLS_ENDPOINT + "tutorcategories"),
  getcoursebyid: (environment.API_URLS_ENDPOINT + "getcoursebyid"),
  generatebatch: (environment.API_URLS_ENDPOINT + "generatebatch"),
  userbookinglist: (environment.API_URLS_ENDPOINT + "getuserbooking"/*:ServiceType*/),
  tutorbookinglist: (environment.API_URLS_ENDPOINT + "getbooking"/*:ServiceType*/),
  createbooking: (environment.API_URLS_ENDPOINT + "create-booking"/*:ServiceType*/),
  createbookingplan: (environment.API_URLS_ENDPOINT + "createbookingplan"/*:ServiceType*/),
  createbookingtutor: (environment.API_URLS_ENDPOINT + "createbookingtutor"/*:ServiceType*/),
  getTutorSchedule: (environment.API_URLS_ENDPOINT + 'gettutorschedule'/*:ServiceType*/),
  batchTutorSchedule: (environment.API_URLS_ENDPOINT + 'batchtutorschedule'/*:ServiceType*/),
  getbatchbyid: (environment.API_URLS_ENDPOINT + 'getbatchbyid'/*:ServiceType*/),
  createBooking: (environment.API_URLS_ENDPOINT + 'create-booking'/*:ServiceType*/),
  getRatings: (environment.API_URLS_ENDPOINT + 'get-rating'/*:ServiceType*/),
  getCourseRating: (environment.API_URLS_ENDPOINT + 'get-course-rating'/*:ServiceType*/),
  getsubcategory: (environment.API_URLS_ENDPOINT + 'vendorsubcat'/*:ServiceType*/),
  getpagebyid: (environment.API_URLS_ENDPOINT + 'getpagebyid'/*:ServiceType*/),
  getfaq: (environment.API_URLS_ENDPOINT + 'faqlist'/*:ServiceType*/),
  postPortofolioImage: (environment.API_URLS_ENDPOINT + 'uploadtutorportfolioimages'/*:ServiceType*/),
  getTutorPortfolioImages: (environment.API_URLS_ENDPOINT + 'gettutorportfolioimages'/*:ServiceType*/),
  deletePortfolioImages: (environment.API_URLS_ENDPOINT + 'deleteimage'/*:ServiceType*/),
  autosearchtutor: (environment.API_URLS_ENDPOINT + 'autosearchtutor'/*:ServiceType*/),
  fetchGroupMessages: (environment.NODE_URLS_ENDPOINT + "api/" + 'roomsMessages/fetch_all'/*:ServiceType*/),
  createbroadcast: (environment.API_URLS_ENDPOINT + 'createbroadcast'/*:ServiceType*/),
  getbroadcastbytutorid: (environment.API_URLS_ENDPOINT + 'getbroadcastbytutorid'/*:ServiceType*/),
  broadcastlist: (environment.API_URLS_ENDPOINT + 'getbroadcast'/*:ServiceType*/),
  deletebroadcast: (environment.API_URLS_ENDPOINT + 'deletebroadcast'/*:ServiceType*/),
  getbroadcastbyid: (environment.API_URLS_ENDPOINT + 'getbroadcastbyid'/*:ServiceType*/),
  gettimezones: (environment.API_URLS_ENDPOINT + 'gettimezones'/*:ServiceType*/),
  getcurrencies: (environment.API_URLS_ENDPOINT + 'getcurrencies'/*:ServiceType*/),
  getRoomByRoomName: (environment.NODE_URLS_ENDPOINT + 'api/rooms/getRoomByRoomName'/*:ServiceType*/),
  saveConference: (environment.API_URLS_ENDPOINT + 'saveconference'/*:ServiceType*/),
  getConferenceByTutorID: (environment.API_URLS_ENDPOINT + 'getconferencebytutorid'/*:ServiceType*/),
  getConferenceList: (environment.API_URLS_ENDPOINT + 'getconferencelist'/*:ServiceType*/),
  deleteConference: (environment.API_URLS_ENDPOINT + 'deleteconference'/*:ServiceType*/),
  getConferenceById: (environment.API_URLS_ENDPOINT + 'getconferencebyid'/*:ServiceType*/),
  getCourseByName: (environment.API_URLS_ENDPOINT + 'getcoursebyname'/*:ServiceType*/),
  getCourse: (environment.API_URLS_ENDPOINT + 'getcourse'/*:ServiceType*/),
  getCourseBatches: (environment.API_URLS_ENDPOINT + 'getcoursebatches'/*:ServiceType*/),
  getallsubcat: (environment.API_URLS_ENDPOINT + 'getallsubcat'/*:ServiceType*/),
  getsubcatdata: (environment.API_URLS_ENDPOINT + 'getsubcatdata'/*:ServiceType*/),
  createorder: (environment.API_URLS_ENDPOINT + 'payment'/*:ServiceType payment request*/),
  completebooking: (environment.API_URLS_ENDPOINT + 'completebooking'/*:ServiceType payment request*/),
  stripepayment: (environment.API_URLS_ENDPOINT + 'stripe-payment'/*:ServiceType payment request*/),
  stripegetproduct: (environment.API_URLS_ENDPOINT + 'stripeGetProductPriceSessionKey'/*:ServiceType payment request*/),
  getbatchtutor: (environment.API_URLS_ENDPOINT + 'getbatchtutor'/*:ServiceType payment request*/),
  batchtutor: (environment.API_URLS_ENDPOINT + 'batchtutor'/*:ServiceType payment request*/),
  getlevels: (environment.API_URLS_ENDPOINT + 'getlevels'/*:ServiceType payment request*/),
  confirmPaymentAndBooking: (environment.API_URLS_ENDPOINT + 'confirmPaymentAndBooking'/*:ServiceType payment request*/),
  getuserstatus: (environment.API_URLS_ENDPOINT + 'getuserstatus'/*:ServiceType payment request*/),
  sendverifyemail: (environment.API_URLS_ENDPOINT + 'sendverifyemail'/*:ServiceType payment request*/),
  verifyuser: (environment.API_URLS_ENDPOINT + 'register/confirm/:token'/*:ServiceType payment request*/),
  getattendance: (environment.API_URLS_ENDPOINT + 'get-attendance'/*:ServiceType payment request*/),
  saveattendance: (environment.API_URLS_ENDPOINT + 'save-attendance'/*:ServiceType payment request*/),
  updateattendance: (environment.API_URLS_ENDPOINT + 'update-attendance'/*:ServiceType payment request*/),
  createrating: (environment.API_URLS_ENDPOINT + 'create-rating'/*:ServiceType payment request*/),
  getstudent: (environment.API_URLS_ENDPOINT + 'get-student'/*:ServiceType payment request*/),
  getUserWithBatches: (environment.API_URLS_ENDPOINT + 'get-user-with-batches'/*:ServiceType payment request*/),
  getstudenthistory: (environment.API_URLS_ENDPOINT + 'get-student-history'),
  instaPayment: (environment.API_URLS_ENDPOINT + 'payment'),
  payCashfree: (environment.API_URLS_ENDPOINT + 'paycashfree'),
  subscriptionCashfree: (environment.API_URLS_ENDPOINT + 'subscriptionCashfree'),
  updateSubscriptions: (environment.API_URLS_ENDPOINT + 'updateSubscriptions'),
  afterpaymentinsta: (environment.API_URLS_ENDPOINT + 'instapayment'),
  getReviewsDoneByMe: (environment.API_URLS_ENDPOINT + 'review-done-by-me'),
  getcombolist: (environment.API_URLS_ENDPOINT + 'combolist'),
  searchcombo: (environment.API_URLS_ENDPOINT + 'searchcombo'),
  callinginfo: (environment.API_URLS_ENDPOINT + 'calling-info'),
  combopage: (environment.API_URLS_ENDPOINT + 'combopage'),
  createfreeuser: (environment.API_URLS_ENDPOINT + 'createfreeuser'),
  bookingplan: (environment.API_URLS_ENDPOINT + 'bookingplan'),
  addtutorcertificate: (environment.API_URLS_ENDPOINT + 'uploadtutorcertificate'),
  gettutorcertificate: (environment.API_URLS_ENDPOINT + 'gettutorcertificate'),
  deletetutorcertificate: (environment.API_URLS_ENDPOINT + 'deletetutorcertificate'),
  savebankdetails: (environment.API_URLS_ENDPOINT + 'savebankdetails'),
  getbankdetails: (environment.API_URLS_ENDPOINT + 'getbankdetails'),
  savewalletdetails: (environment.API_URLS_ENDPOINT + 'savewalletdetails'),
  getnotificationbyuser: (environment.API_URLS_ENDPOINT + 'getnotificationbyuser'),
  getbankpaymentdetail: (environment.API_URLS_ENDPOINT + 'getbankpaymentdetail'),
  getwallettype: (environment.API_URLS_ENDPOINT + 'getwallettype'),
  getclasstype: (environment.API_URLS_ENDPOINT + 'classtype'),
  getclasslocation: (environment.API_URLS_ENDPOINT + 'classlocation'),
  gettutorsubjectid: (environment.API_URLS_ENDPOINT + 'gettutorsubjectid'),
  gettutorsubject: (environment.API_URLS_ENDPOINT + 'tutorsubject'),
  getcountry: (environment.API_URLS_ENDPOINT + 'getcountry'),
  tutorsubjectbyname: (environment.API_URLS_ENDPOINT + 'tutorsubjectbyname'),
  vendorsubcat: (environment.API_URLS_ENDPOINT + 'vendorsubcat'),
  searchprofession: (environment.API_URLS_ENDPOINT + 'search-profession'),
  searchcountry: (environment.API_URLS_ENDPOINT + 'search-country'),
  savetutorsubject: (environment.API_URLS_ENDPOINT + 'savetutorsubject'),
  getfaqcourse: (environment.API_URLS_ENDPOINT + 'getfaqcourse'),
  createfaq: (environment.API_URLS_ENDPOINT + 'createfaq'),
  deletefaq: (environment.API_URLS_ENDPOINT + 'deletefaq'),
  updatefaqdetail: (environment.API_URLS_ENDPOINT + 'updatefaqdetail'),
  getlessonbycourse: (environment.API_URLS_ENDPOINT + 'getlessonbycourse'),
  createlesson: (environment.API_URLS_ENDPOINT + 'createlesson'),
  deletelesson: (environment.API_URLS_ENDPOINT + 'deletelesson'),
  updatelessondetail: (environment.API_URLS_ENDPOINT + 'updatelessondetail'),
  sendaudiorecording: (environment.API_URLS_ENDPOINT + 'send-audio-recording-mail'),
  getRecordings: (environment.API_URLS_ENDPOINT + 'get-recordings'),
  sendMailToTutor: (environment.API_URLS_ENDPOINT + 'send-mail-tutor'),
  getCommonSearch: (environment.API_URLS_ENDPOINT + 'getcommonsearch'),
  getTutorCategoryIdByName: (environment.API_URLS_ENDPOINT + 'gettutorcategoryidbyname'),
  getCategoryIdFromName: (environment.API_URLS_ENDPOINT + 'getcategoryidfromname'),
  switchliveupcomingstate: (environment.API_URLS_ENDPOINT + 'switchliveupcomingstate'),
  checkvideocoursepayment: (environment.API_URLS_ENDPOINT + 'checkvideocoursepayment'),
  createevent: (environment.API_URLS_ENDPOINT + 'createevent'),
  removeevent: (environment.API_URLS_ENDPOINT + 'removeevent'),
  getemailbycode: (environment.API_URLS_ENDPOINT + 'getemailbycode'),
  getbookingusers: (environment.API_URLS_ENDPOINT + 'getbookingusers'),
  getattendancebyid: (environment.API_URLS_ENDPOINT + 'getattendancebyid'),
  getstudentappointments: (environment.API_URLS_ENDPOINT + 'getstudentappointments'),
  gethomepagebanners: (environment.API_URLS_ENDPOINT + 'gethomepagebanners'),
  createBigblueConf: (environment.API_URLS_ENDPOINT + 'create-bbb-conf'),
  createBigblueConfJoinLink: (environment.API_URLS_ENDPOINT + 'create-join-link-bbb-conf'),
  updateBatchListOrder: (environment.API_URLS_ENDPOINT + 'updatebatchlistorder'),
  sendtutorwhatsapp: (environment.API_URLS_ENDPOINT + 'sendtutorwhatsapp'),
  removeBatch: (environment.API_URLS_ENDPOINT + 'removebatch'),
  getAllStudio: (environment.API_URLS_ENDPOINT + 'getAllStudio'),
  getStudioDetail: (environment.API_URLS_ENDPOINT + 'getStudioDetail'),
  sendcartmessages: (environment.API_URLS_ENDPOINT + 'sendcartmessages'),
  getSubscriptionDetails: (environment.API_URLS_ENDPOINT + 'getSubscriptionDetails'),
  tutorSubscription: (environment.API_URLS_ENDPOINT + 'tutorSubscription'),
  applyCouponCode: (environment.API_URLS_ENDPOINT + 'applyCouponCode'),
  getAllJoinTypes: (environment.API_URLS_ENDPOINT + 'get_all_join_type'),
  stripeSubscription: (environment.API_URLS_ENDPOINT + 'stripeSubscription'),
  cookieConsent: (environment.API_URLS_ENDPOINT + 'cookieConsent'),
  getClassTargetGroupType: (environment.API_URLS_ENDPOINT + 'getClassTargetGroupType'),
  sendRequirement: (environment.API_URLS_ENDPOINT + 'send-requirement'),
  getTestimonial: (environment.API_URLS_ENDPOINT + 'gettestmonials'),
  homePageSearch: (environment.API_URLS_ENDPOINT + 'homepage-search'),
  getExpertEarningTransaction: (environment.API_URLS_ENDPOINT + 'get-expert-earning-transaction'),
  wizPassMembershipSubscription: (environment.API_URLS_ENDPOINT + 'wiz-pass-membership'),
  getMembershipBooking: (environment.API_URLS_ENDPOINT + 'get-membership-booking'),
  getUserWizpass: (environment.API_URLS_ENDPOINT + 'get-user-wizpass'),
  wizzPassRedeem: (environment.API_URLS_ENDPOINT + 'wizzPassRedeem'),
  getWizzPassRedeemHis: (environment.API_URLS_ENDPOINT + 'getWizzPassRedeemHis'),
  getTags: (environment.API_URLS_ENDPOINT + 'getTags'),
  getCourseByTag: (environment.API_URLS_ENDPOINT + 'getCourseByTag'),
  getTagsWithCourses: (environment.API_URLS_ENDPOINT + 'getTagsWithCourses'),
  getTutorSubjectTags: (environment.API_URLS_ENDPOINT + 'getTutorSubjectTags'),
  getMeetingRecordings: (environment.API_URLS_ENDPOINT + 'getMeetingRecordings'),
  getInsights: (environment.API_URLS_ENDPOINT + 'getDailyInsights')

}
