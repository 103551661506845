import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { MatIconModule } from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgmCoreModule } from '@agm/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        TranslocoModule,
        LazyLoadImageModule,
        AgmCoreModule.forRoot({
            libraries: ['places'],
            apiKey: 'AIzaSyB3kR6ZozHtSeOGsj5hATM3Dvuzuk8YxP4',
        }),
        NgxSkeletonLoaderModule

    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        MatIconModule,
        StarRatingComponent,
        LazyLoadImageModule,
        NgxSpinnerModule,
        AgmCoreModule,
        NgxSkeletonLoaderModule


    ],
    declarations: [
        StarRatingComponent,
    ]
})
export class SharedModule {
}
