import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthServices } from 'src/app/core/services/auth.service';
import * as myGlobals from 'src/app/core/services/globals';
import { LocalStorageService } from 'src/app/core/services/local-Storage';
export interface DialogData {};

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  public senddstatus ="VERIFY EMAIL - Check your Inbox";
  params = {};
  constructor(public dialogRef: MatDialogRef<EmailComponent>,
    private authservice: AuthServices,
    private localService: LocalStorageService) { }

  ngOnInit(): void {
  }

  onAction(action): void {
    if (action) {
      this.params['user_id'] = this.localService.get(myGlobals.STORAGE_KEYS.USER_ID);
      this.authservice.sendVerifyEmail(this.params).subscribe(result => {
        if( result.body.status_code ==200){
          this.senddstatus = result.body.message;
          setTimeout(() => {       
             this.dialogRef.close(action);
          }, 4000);
        }
   
      });
    } else{
      this.dialogRef.close(true);
    }
  }

}
