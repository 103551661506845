import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatChip, MatChipList } from '@angular/material/chips';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CategoryService } from 'src/app/core/services/category.service';
import { TutorService } from 'src/app/core/services/tutor.service';

@Component({
  selector: 'app-profession-category',
  templateUrl: './profession-category.component.html',
  styleUrls: ['./profession-category.component.scss']
})
export class ProfessionCategoryComponent implements OnInit {
  categoryArr = [];
  $unsubscribe: Subject<any> = new Subject<any>();
  selectable: boolean = true;
  multiSelect: boolean = false;
  isVendor: boolean = false;
  selectedProfession: any = [];
  constructor(
    private catervice: CategoryService,
    private tutorservice: TutorService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.isVendor = data?.isVendor || false;
    this.multiSelect = data?.isMultiSelect || false;
    if (data.selectedProfession) {
      this.selectedProfession = data.selectedProfession
    }

  }

  ngOnInit(): void {
    if(this.isVendor){

      this.getCourseCategories();
  
    }else{
      this.getTutorCategories();

    }
}
getCourseCategories() {
  const request = {
    "status": 0,
  }
 
  this.catervice.getAllCategory({ 'status': '0' }).pipe(takeUntil(this.$unsubscribe)).subscribe((result) => {
    if (result.status == 200) {
      this.categoryArr = result.body.categories;
    }
  });

}
  getTutorCategories() {
    const request = {
      "status": 0,
    }
    this.tutorservice.getTutorCat().pipe(takeUntil(this.$unsubscribe)).subscribe(result => {
      if (result.status == 200) {
        this.categoryArr = result.body.categories;
      }
    });
  }
  toggleSelection(chip: MatChip) {
    chip.toggleSelected();
    if (chip.selected) {
      if (!this.multiSelect) {
        this.selectedProfession = [];
      }
      this.selectedProfession.push(chip.value)
    } else {
      this.selectedProfession = this.selectedProfession.filter(item => { return item != chip.value });
    }
  }

  professionSetSelected(Profession: any): boolean {
    return this.selectedProfession.includes(Profession);
  }

  ngOnDestroy() {
    this.$unsubscribe.next(null);
    this.$unsubscribe.complete();
  }

}
