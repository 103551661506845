import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute,Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';

import { filter, takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from 'src/@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from 'src/@fuse/components/navigation';
import { Navigation } from 'src/app/core/navigation/navigation.types';
import { NavigationService } from 'src/app/core/navigation/navigation.service';
import { BehaviorsService } from 'src/app/core/services/behaviors.service';

import { AuthService } from 'src/app/core/auth/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-Storage';
import * as myGlobals from 'src/app/core/services/globals';
import { ChatService } from 'src/app/core/services/chat.service';
import { MatDialog } from '@angular/material/dialog';
import { PostRequestDialogComponent } from 'src/app/layout/common/post-request-dialog/post-request-dialog.component';


@Component({
    selector: 'classic-layout',
    templateUrl: './classic.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassicLayoutComponent implements OnInit, OnDestroy, AfterViewChecked {
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    userPanelNavEnable: boolean = false;
    mobileMenuOpen: boolean = false;
    selectLocation ="";
    isLogin: boolean = false;
    userRole: any;
    popupformstatus=false;
    dialogRef:any;
   public locationSet:any;


    /**
     * Constructor
     */
    
    constructor(
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _behaviorsService: BehaviorsService,
        private cdRef: ChangeDetectorRef,
        private localService: LocalStorageService,
        private _authService: AuthService,
        private chatService: ChatService,
        private location: Location,      
        private _matDialog: MatDialog,

    ) {
        
        

        this.popupformstatus = this.localService.get(myGlobals.STORAGE_KEYS.POPUPFORM);
        this._router.events.subscribe((event: Event) => {
  
            if (event instanceof NavigationEnd) {
                // Hide loading indicator
                this.locationSet ='';
                if((event.url!="/") && (event.url != "/courses") && (event.url != "/studios") ){
                    this.locationSet = this.localService.get('locationSet');
                }
            }

           
        });

        this._authService.getIsLogin().subscribe(result => {
            this.isLogin = result ? result : localStorage.getItem('WIZHOB_TOKEN') ? true : false;
            this.userRole = this.localService.get('usertype');
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.getMenu();

        this._authService.getIsLogin().subscribe(result => {
            this.isLogin = result ? result : localStorage.getItem('WIZHOB_TOKEN') ? true : false;

            this.hideShowMenu();
        });
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
                this.hideShowMenu();
            });

            if((this._router.url!="/") && (this._router.url != "/courses")){
                this.locationSet = this.localService.get('locationSet');
            }

        
    }
    
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }


    ngAfterViewChecked() {
        let authRedirectUrl: any = '';
        this._behaviorsService.authguardRouteUrl
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                authRedirectUrl = data;

            });

        this.userPanelNavEnable = authRedirectUrl === this._router.url;

        this.cdRef.detectChanges();
 
    }
    ngAfterViewInit() {
       
    }
    getMenu() {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
                this.navigation.horizontal.forEach(e => {
                    if (e.id == 'sign-out') {

                        e.function = (signOut) => this.signOut()
                    }
                });
            });
    }
    changeLocation(event): void {
        var urlstr="";
        
        switch(event.value) { 
            case 0: {
                urlstr = '/';
                this.localService.remove('locationSet');               
                 break; 
              } 
          case 1: {
            urlstr = '/courses/jointype/courses';
            this.localService.set('locationSet',1);
             break; 
          } 
          case 2: { 
            urlstr = '/courses/jointype/classes';
            this.localService.set('locationSet',2);
            break; 
          } 
          case 3: { 
            urlstr = '/studios';
            this.localService.set('locationSet',3);
            break; 
         } 
         case 4: { 
            break; 
         } 
         case 5: { 
            break; 
         } 
          default: { 
            urlstr = '/';
            this.localService.remove('locationSet');
             break; 
          } 
       } 
     
       this.location.go(urlstr);
       window.location.reload();
     
    }
    hideShowMenu() {


        this.navigation.horizontal.forEach(e => {
            let item = e.id;
            e.hidden = (item) => true;
            if (e.id == 'login' || e.id == 'teach') {

                let isLogin = this.isLogin;

                e.hidden = (login) => isLogin;
                e.hidden = (teach) => isLogin;


            }
            if (e.id == 'courses' || e.id == 'tutors' || e.id == 'studios' || e.id == 'recordings') {
                let item = e.id;
                e.hidden = (item) => false;
            }
        /*     if(this.localService.get(myGlobals.STORAGE_KEYS.CURRENCY)!="INR" &&  e.id == 'studios'){
                     e.hidden = (studio) => true;
            } */
            else if (this.isScreenSmall) {

                if ((this.userRole == '3') && (e.id == 'divider' || e.id == 'dashboard' || e.id == 'profile' || e.id == 'sign-out')) {
                    e.hidden = (item) => false;
                }
                else if ((this.userRole == '2') && (e.id == 'divider' || e.id == 'expertdashboard' || e.id == 'editextradetails' || e.id == 'profile' || e.id == 'publicprofile' || e.id == 'sign-out')) {
                    e.hidden = (item) => false;
                }
                else if ((this.userRole == '4') && (e.id == 'divider' || e.id == 'studiodashboard' || e.id == 'editextradetailstudio' || e.id == 'profile' || e.id == 'publicprofile' || e.id == 'sign-out')) {
                    e.hidden = (item) => false;
                }
            }


        });
    }
    signOut() {
        let userNodeId = this.localService.get(myGlobals.STORAGE_KEYS.NODE_USER_ID);
        if (userNodeId) {
            this.chatService.goOffline(userNodeId);
        }
        this.localService.clear();
        this._authService.signOut();
        // this._authService.setIsLogin(false);
        this._router.navigate(['/']);
    }

    openPostReqDialog() {
        const dialogRef02 = this._matDialog.open(PostRequestDialogComponent);
        dialogRef02.afterClosed().subscribe(result => {
           
        });
    }


}
