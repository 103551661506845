<!-- Navigation -->
<fuse-vertical-navigation *ngIf="isScreenSmall" class="bg-wizhob-primary dark print:hidden z-10 mt-9rem" [mode]="'over'"
    [name]="'mainNavigation'" [navigation]="navigation.horizontal" [position]="'right'" [opened]="false"
    [transparentOverlay]="true">
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 my-5">
            <!-- Light version -->
    
        </div>
    </ng-container>
</fuse-vertical-navigation>

<fuse-vertical-navigation *ngIf="userPanelNavEnable" class=" dark bg-wizhob-primary print:hidden z-10" [mode]="'side'"
    [name]="'dashboardNavigation'"
    [navigation]="(userRole!='3')?((userRole=='4')?navigation.studioPanelL:navigation.expertPanelL):navigation.userPanelL"
    [position]="'left'" [opened]="!isScreenSmall">
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 my-5">
            <!-- Light version -->
        
        </div>
    </ng-container>
</fuse-vertical-navigation>
<div class="overflow-hidden w-full">
    <section class="flex w-full  text-white" class="top-header">

        <!-- Header -->
        <div class="relative flex flex-0 items-center w-full py-2 px-4 md:px-6 z-49 print:hidden">

            <!-- Components -->
            <ng-container>

                <div *ngIf="!isScreenSmall" style="width:200px ;">
                    <mat-select [placeholder]="'Browse'" [ngModel]="locationSet"
                        (selectionChange)="changeLocation($event)" panelClass="testClass"
                        class="locationSelect flex items-center mx-auto lg:mr-8 floating-label1">
                        <mat-option [value]="0" class="text-pink-500">Clear</mat-option>

                        <mat-option [value]="1">Online Courses</mat-option>
                        <mat-option [value]="2">Online Anytime Classes</mat-option>
<!--                         <mat-option [value]="3">Studios Close to You</mat-option>
 -->

                    </mat-select>
                </div>
                <div *ngIf="!isScreenSmall" class="w-full mx-2">

                    <div id="slideset2">
                        <div>
                            <img alt="banner img" src="assets/img/home/webp/banner01.webp" class="object-scale-down"  height="60%" width="100%"> <br/>
                        </div>
                        <div>
                            <img alt="banner img" src="assets/img/home/webp/banner02.webp" class="object-scale-down"  height="60%" width="100%"><br/>
   
                        </div>
                        <div>
                            <img alt="banner img" src="assets/img/home/webp/banner03.webp" class="object-scale-down"  height="60%" width="100%"><br/>
   
                        </div>
                      </div>

             
                </div>
                <div class="flex items-center hobReq ml-auto text-white space-x-0.5 sm:space-x-2">
                    <button mat-button (click)="openPostReqDialog()">
                        <mat-icon class="text-white" [svgIcon]="'heroicons_solid:document-add'"></mat-icon>
                        <span class="text-white ml-2"> Post A Hobby Requirement </span>
                    </button>
                </div>

            </ng-container>
        </div>
    </section>
    <!-- Wrapper -->
    <section class="flex flex-col flex-auto w-full min-w-0">

        <!-- Header -->
        <div
            class="relative flex flex-0 items-center w-full h-22 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
            <!-- Navigation toggle button -->
            <button *ngIf="userPanelNavEnable" mat-icon-button (click)="toggleNavigation('dashboardNavigation')">
                <mat-icon>menu</mat-icon>
            </button>
            <!-- Components -->
            <ng-container *ngIf="!isScreenSmall">
                <!-- Logo -->
                <div class="flex items-center mx-2 lg:mr-8">
                    <a routerLink="/"> <img class="w-full" alt="Wizhob Logo" src="assets/images/logo/wizhob-logo.png">
                    </a>
                </div>
                <!-- Horizontal navigation -->
                <search [appearance]="'basic'"></search>

                <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">

                    <fuse-horizontal-navigation class="mr-2" [name]="'horizontalNav'"
                        [navigation]="navigation.horizontal">
                    </fuse-horizontal-navigation>

                    <!--  <languages></languages> -->

                    <user *ngIf="isLogin" (signOut)="signOut()"></user>
                </div>
            </ng-container>

            <ng-container *ngIf="isScreenSmall">
                <!-- Logo -->
                <div class="flex items-center mx-auto lg:mr-8">
                    <a routerLink="/"> <img class="w-36" alt="Wizhob Logo" src="assets/images/logo/wizhob-logo.png">
                    </a>
                </div>
                <search class="mr-2" [appearance]="'bar'"></search>
                <div mat-icon-button (click)="toggleNavigation('mainNavigation')">
                    <mat-icon>menu</mat-icon>
                </div>

            </ng-container>



        </div>

        <!-- Content -->
        <div class="flex flex-col flex-auto">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>


    </section>
</div>