export const regEx = {
    distributorId: /^[0-9]{8}$/,
    mobile: '[6-9]{1}[0-9]{9}',
    email: /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
    name: /^[A-Z].*$/,
    firstCapitalnospecial: /^[A-Z].[A-Za-z0-9 ]+$/,
    nospecial: /^[a-zA-Z0-9 ]+$/,
    namenumber: /^[1-9A-Z].*$/,
    integervalue: /^-?[0-9]\\d*(\\.\\d*)?$/,
}

export const WIZHOB_ADMIN_PANEL_KEYS = {
    WIZHOB_ENCRYPTION_KEY: '9mMPIi1LIuBX59nphrzlVg==',
}
export const CommonConstants = {
    BRAND: 'Wizhob',
    brandtype: "Hobby",
    brandtypes: "Hobbies",
}
export const STORAGE_KEYS = {
    TOKEN_KEY: 'WIZHOB_TOKEN',
    USER_ID: 'USER_ID',
    USER_ROLE: 'USER_ROLE',
    NODE_USER_ID: 'NODE_USER_ID',
    USER_NAME: 'USER_NAME',
    EMAIL: 'EMAIL',
    TIMEZONE: 'TIMEZONE',
    CURRENCY: 'CURRENCY',
    MOBILE: 'MOBILE',
    PHONECODE: 'PHONECODE',
    POPUPFORM:'POPUPFORM'
}