export const WIZHOB_ADMIN_PANEL_ROUTE = {
    // PRODUCT_CATALOGUE: {
    //     PRICE: '\product/price'
    // },
    // CATEGORY_CATALOGUE: {
    //     CATEGORY_INFORMATION: 'category/category-information',
    //     META_INFORMATION: 'category/meta-information',
    //     CATEGORY_PRODUCTS: 'category/category-products'
    // }
};

export const SUB_ROUTE = {
    DASHBOARD: 'dashboard',
};

export const STATUS_LIST = [
    { value: true, key: 'Yes' },
    { value: false, key: 'No' },
];

export const TIME_INTERVAL = ['00', '15', '30', '45'];

export const SUPPORTED_FILES_EXTS = [
    'application/jpg', 'image/png',
    'image/jpeg',
];

export const SUPPORTED_DOCUMENTS_EXTS = [
    'application/pdf', 'image/docs',
    'image/doc', 'application/msword'
];

export const SUPPORTED_VIDEOS_EXTS = [
    'video/3gpp',
    'video/mp4',
    'video/webm',
    'video/x-matroska'
];

export const PRODUCT_IMAGE_FILE_SIZE = 2 * 1024 * 1024;       // 1 mb

export const LOCATION_URL1 = "https://ipgeolocation.abstractapi.com/v1/?api_key=ca143f2c5e07422ba4324ecca69776e9";

/**
 * @description alert type { error, success, warning }
 */
export enum ALERT_TYPE {
    ERROR,
    SUCCESS,
    WARNING
}
