import { Route } from '@angular/router';
import { LayoutComponent } from 'src/app/layout/layout.component';
import { InitialDataResolver } from './app.resolvers';


// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'classic'
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [

            {
                path: '', data: { title: 'Wizhob | Learn Hobbies Online' },
                loadChildren: () => import('./main/pages/home-page/home-page.module').then(m => m.HomePageModule)
            },
            { path: '', loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule) },
            { path: '', loadChildren: () => import('./main/auth/auth.module').then(m => m.AuthModule) },
            {
                path: '**',
                redirectTo: '/'
            }
        ]
    },


];
