import { Injectable } from '@angular/core';
import * as Urls from '../utility/Urls';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TutorService {

  constructor(private apiService: ApiService,
    private router: Router, private http: HttpClient) { }

  getTutorData(params) {
    return this.apiService.post(Urls.ServiceEnum.getTutors, params);
  }

  getLanguages() {
    return this.apiService.get(Urls.ServiceEnum.getLanguages);
  }

  getTutorCat() {
    return this.apiService.get(Urls.ServiceEnum.gettutorcat);
  }

  getExpertise() {
    return this.apiService.get(Urls.ServiceEnum.getexpertise);
  }

  getProfession() {
    return this.apiService.get(Urls.ServiceEnum.getexpertise);
  }
  getLevels() {
    return this.apiService.get(Urls.ServiceEnum.getlevels);
  }

  getTutorDashboard(params) {
    return this.apiService.post(Urls.ServiceEnum.tutordashboard, params);
  }

  getAutoSerachTutor(params) {
    return this.apiService.post(Urls.ServiceEnum.autosearchtutor, params);
  }

  getAutoSearchProfession(params) {
    return this.apiService.post(Urls.ServiceEnum.searchprofession, params);
  }

  getAutoSearchCountry(params) {
    return this.apiService.post(Urls.ServiceEnum.searchcountry, params);
  }

  postTutorCertificate(params: any): Observable<any> {
    return this.http.post(Urls.ServiceEnum.addtutorcertificate, params);
  }

  getTutorCertificate(params) {
    return this.apiService.post(Urls.ServiceEnum.gettutorcertificate, params);
  }

  deleteTutorCertificate(params) {
    return this.apiService.post(Urls.ServiceEnum.deletetutorcertificate, params);
  }

  saveTutorSubject(value) {
    return this.apiService.post(Urls.ServiceEnum.savetutorsubject, value);
  }
  getCommonSearch(value) {
    return this.apiService.post(Urls.ServiceEnum.getCommonSearch, value);
  }

  getTutorCategoryIdByName(value) {
    return this.apiService.post(Urls.ServiceEnum.getTutorCategoryIdByName, value);
  }
  sendTutorWhatsapp(value) {
    return this.apiService.post(Urls.ServiceEnum.sendtutorwhatsapp, value);
  }
  sendCartMessages(value) {
    return this.apiService.post(Urls.ServiceEnum.sendcartmessages, value);
  }

  tutorSubscription(data) {
    return this.apiService.post(Urls.ServiceEnum.tutorSubscription, data);
  }

  getMeetingRecordings(data) {
    return this.apiService.post(Urls.ServiceEnum.getMeetingRecordings, data);

  }
}
