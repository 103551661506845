/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, Subject, throwError, of } from 'rxjs';
import * as Urls from '../utility/Urls';
import { ApiService } from './api.service';
import { LocalStorageService } from './local-Storage';
import { HttpClient } from '@angular/common/http';
import * as Constant from '../utility/Constant';
import * as myGlobals from './globals';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthServices {
  // eslint-disable-next-line @typescript-eslint/ban-types
  loginUrl: String = '';

  private isLogin = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  _authenticated: any;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  accessToken: any;

  constructor(private http: HttpClient,
    private apiService: ApiService,
    private localStorage: LocalStorageService,
    private router: Router) { }

  getToken() {
    const token = this.localStorage.get(myGlobals.STORAGE_KEYS.TOKEN_KEY);
    return token;
  }
  gethomepagebanners() {
    return this.apiService.get(Urls.ServiceEnum.gethomepagebanners);
  }

  // setIsLogin(val: boolean) {
  //   /*   if(!val){
  //        this.localStorage.clear();
  //      } */
  //   this.isLogin.next(val);
  // }

  // getIsLogin() {
  //   return this.isLogin;
  // }

  otpOnMobile(num: any) {
    return this.apiService.post(Urls.ServiceEnum.Otp, num);
  }

  verifyOtp(otp: any) {
    return this.apiService.post(Urls.ServiceEnum.Verifyotp, otp);
  }

  register(data: any): Observable<any> {
    return this.http.post(Urls.ServiceEnum.register, data);
  }

  public isAuthenticated() {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

  getTimeZones() {
    return this.apiService.get(Urls.ServiceEnum.gettimezones);
  }

  getCurrencies() {
    return this.apiService.get(Urls.ServiceEnum.getcurrencies);
  }

  getUserStatus(params: any) {
    return this.apiService.post(Urls.ServiceEnum.getuserstatus, params);
  }

  sendVerifyEmail(params) {
    return this.apiService.post(Urls.ServiceEnum.sendverifyemail, params);
  }

  getCallInfo(data) {
    return this.apiService.post(Urls.ServiceEnum.callinginfo, data);
  }

  sendaudiorecording(params) {
    return this.apiService.post(Urls.ServiceEnum.sendaudiorecording, params);
  }

  getRecordings(data) {
    return this.apiService.post(Urls.ServiceEnum.getRecordings, data);
  }

  userLocationData() {
    return this.http.get(Constant.LOCATION_URL1).pipe(
      catchError((error) => {

        return of(null); // Placeholder value (you can modify this)
      }),
      switchMap((response) => {
        if (response === null) {
          return this.staticUserLocationData();
        }
        // Otherwise, return the HTTP response.
        return of(response);
      })
    );
  }

  staticUserLocationData(): Observable<any> {
    return of({
      "ip": "103.48.198.141",
      "hostname": '103.48.198.141',
      "continent_code": "AS",
      "continent_name": "Asia",
      "country_code2": "IN",
      "country_code3": "IND",
      "country_name": "India",
      "country_capital": "New Delhi",
      "state_prov": "Delhi",
      "district": "",
      "city": "Delhi",
      "zipcode": "110019",
      "latitude": 28.55059,
      "longitude": 77.25185,
      "is_eu": "false",
      "calling_code": "+91",
      "country_tld": ".in",
      "languages": "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
      "country_flag": "https://ipgeolocation.io/static/flags/in_64.png",
      "isp": "Excitel Broadband Private Limited",
      "connection_type": "",
      "organization": "Excitel Broadband Private Limited",
      "asn": "AS133982",
      "geoname_id": 9959211,
      "currency": {
        "name": "Indian Rupee",
        "code": "INR",
        "symbol": "₹",
      },
      "time_zone": {
        "name": "Asia/Kolkata",
        "offset": 5.5,
        "current_time": "2023-09-21 15:38:19.544+0530",
        "current_time_unix": "1.695290899544E9",
        "is_dst": "false",
        "dst_savings": 0,
      }
    });
  }

  public logout() {
    //private currentUserSubject = new BehaviorSubject<string>(this.localStorage.get('USER_NAME'));
    // public currentUser = this.currentUserSubject.asObservable();
    // remove user from local storage to log user out
    //this.localStorage.clear();

    // Clear all cookies
    const cookies = document.cookie.split('; ');
    for (let c = 0; c < cookies.length; c++) {
      const d = window.location.hostname.split('.');
      while (d.length > 0) {
        const cookieBase = encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        const p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    }

    // Redirect to login
    this.router.navigate(['login']);
  }

  /**
  * Sign in
     *
     * @param credentials
     */
  signIn(singIndata): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }
    this.accessToken = singIndata.access_token;

    // Set the authenticated flag to true
    this._authenticated = true;

    // Store the user on the user service
    // this._userService.user = singIndata.user;

    // Return a new observable with the response
    // return true;



    // return this._httpClient.post('api/auth/sign-in', credentials).pipe(
    //     switchMap((response: any) => {

    //         // Store the access token in the local storage
    //         this.accessToken = response.accessToken;

    //         // Set the authenticated flag to true
    //         this._authenticated = true;

    //         // Store the user on the user service
    //         this._userService.user = response.user;

    //         // Return a new observable with the response
    //         return of(response);
    //     })
    // );
  }


}
