<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
    <div mat-icon-button *ngIf="!opened" (click)="open()">
        <mat-icon>search</mat-icon>
    </div>
    <div class="absolute inset-0 flex items-center flex-shrink-0 z-40  bg-yellow-200" *ngIf="opened" @slideInTop
        @slideOutTop>
        <mat-icon class="absolute  ml-10 sm:ml-8">search</mat-icon>
        <input class="bar-input w-full h-full px-18 sm:px-18" [formControl]="searchControl"
            [matAutocomplete]="matAutocomplete" [placeholder]="'Search Courses | Experts | Categories'"
            (keydown)="onKeydown($event)" #barSearchInput>
        <mat-autocomplete class="max-h-128 sm:px-2 border-t rounded-b shadow-md" [disableRipple]="true"
            #matAutocomplete="matAutocomplete">
            <mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                *ngIf="resultSets && !resultSets.length">
                No results found!
            </mat-option>
            <ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                <mat-optgroup class="flex items-center h-10 px-2">
                    <span
                        class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.type.toUpperCase()}}</span>
                </mat-optgroup>
                <ng-container *ngFor="let result of resultSet.data; trackBy: trackByFn">
                    <mat-option
                        class="group relative h-9 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                        [routerLink]="result.url">
                        <ng-container *ngIf="resultSet.type === 'Hobby Expert'">
                            <ng-container *ngTemplateOutlet="contactResult; context: {$implicit: result}">
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="resultSet.type != 'Hobby Expert'">
                            <ng-container *ngTemplateOutlet="commonResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete>
        <button class="absolute top-1/2 right-5 sm:right-7 flex-shrink-0 w-10 h-10 -mt-5" mat-icon-button
            (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</ng-container>

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'">
    <div class="w-full sm:min-w-50 lg:min-w-90">
        <mat-form-field class="fuse-mat-no-subscript w-full">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput [formControl]="searchControl" [matAutocomplete]="matAutocomplete"
                [placeholder]="'Search Courses | Experts | Categories'" (keydown)="onKeydown($event)">
        </mat-form-field>
        <mat-autocomplete class="max-h-128 mt-1 rounded" [disableRipple]="true" #matAutocomplete="matAutocomplete">
            <mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                *ngIf="resultSets && !resultSets.length">
                No results found!
            </mat-option>
            <ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                <mat-optgroup class="flex items-center h-10 px-2">
                    <span
                        class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.type.toUpperCase()}}</span>
                </mat-optgroup>
                <ng-container *ngFor="let result of resultSet.data; trackBy: trackByFn">
                    <mat-option
                        class="group relative h-9 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                        [routerLink]="result.url">
                        <ng-container *ngIf="resultSet.type === 'Hobby Expert'">
                            <ng-container *ngTemplateOutlet="contactResult; context: {$implicit: result}">
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="resultSet.type != 'Hobby Expert'">
                            <ng-container *ngTemplateOutlet="commonResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete>
    </div>
</ng-container>

<!-- Contact result template -->
<ng-template #contactResult let-result>
    <div class="flex items-center">
        <div
            class="flex flex-shrink-0 items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-primary-100 dark:bg-primary-800">
            <img *ngIf="result.profile_image" [defaultImage]="'assets/img/default_profile.webp'"
                [lazyLoad]="result.profile_image" [useSrcset]="true">
            <mat-icon class="m-0 icon-size-5 text-primary dark:text-primary-400" *ngIf="!result.profile_image">account_circle</mat-icon>
        </div>
        <div class="ml-3 truncate">
            <span [innerHTML]="result.name"></span>
        </div>
    </div>
</ng-template>

<!-- Page result template -->
<ng-template #commonResult let-result>
    <div class="flex flex-col">
        <div class="truncate leading-normal" [innerHTML]="result.name"></div>

    </div>
</ng-template>