<div class="mat-dialog-header" mat-dialog-title>
    <div class="flex justify-between align-items-center my-2">
        <h2 class="my-0 font-bold text-center">Verify OTP </h2>
    </div>
</div>
<div mat-dialog-content>
    <form [formGroup]="verifyOtpForm">

        <div class="flex flex-col gt-sm:flex-row md:w-100">
            <mat-form-field class="flex-auto" appearance="outline">
                <mat-label>Enter OTP</mat-label>
                <input matInput placeholder="Enter OTP" formControlName="otp">
            </mat-form-field>
        </div>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="false">Close</button>
    <button mat-flat-button color="primary" class="bg-wizhob-primary" (click)="verifyOTP()">Verify OTP
        <!-- <mat-spinner diameter="24" color="accent"></mat-spinner> -->
    </button>
</mat-dialog-actions>