import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BehaviorsService {

  public authguardRouteUrl: BehaviorSubject<any> = new BehaviorSubject<boolean>(null);
  public expertsFilterEnable = new BehaviorSubject<any>(false);
  public onlineUserList = new BehaviorSubject<any>([]);
  public tutorCartItem = new BehaviorSubject<any>(null);

  constructor() { }

  setAuthguardRouteUrl(url: any) {
    this.authguardRouteUrl.next(url);
  }

  setExpertsFilterEnable(data: boolean) {
    this.expertsFilterEnable.next(data);
  }
  setOnlineUserList(data: any) {
    this.onlineUserList.next(data);
  }
  setTutorCartItem(data: any) {
    this.tutorCartItem.next(data);
  }
}
