import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import AOS from "aos";
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('MzIwNTg2MkAzMjMxMmUzMTJlMzMzNVNnN2I4L1dUN29ibC80Y2UwQUpYc1lFZTluSzE2MmNOOGlwb3NZZmk3WEE9;MzIwNTg2M0AzMjMxMmUzMTJlMzMzNUQ5K3JlSXVzcDJvVFdoT3F0WVluT0c3S3k4bU92ZjRPUEJyUVFKZ2hiQ2c9;Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRbQlljTH5Qc0xmXn1ddnY=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9nSXpTc0VgXHZbd3dVRmI=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdkBjWnpXdXNWQmdZ;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3pTckVmWXxbeXFSRWZfUA==;MzIwNTg2OEAzMjMxMmUzMTJlMzMzNU1zeTJYaFBNblRnaVk2V2ZPUnAvNndvOVh5M29jSVRmbENadXp5UEpBcGs9;MzIwNTg2OUAzMjMxMmUzMTJlMzMzNUdDYit4eGFKd3Q2dzJsSjdWRFRjUTRLUkNiU0UxUGZzTWd2ZnNkbHA0VHc9;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdkBjWnpXdXNQRmRV;MzIwNTg3MUAzMjMxMmUzMTJlMzMzNWZvaFZibzR3VVcwTUo1WkNtOXczUjhUTjNlemdEMWRpcXNuR0szZDI0N1E9;MzIwNTg3MkAzMjMxMmUzMTJlMzMzNW9pUTlSQ1Q2eXFZdWd6bjYyVytmQ2U4akVLeFFEUUMvNUkxLzI3eEhSTEE9;MzIwNTg3M0AzMjMxMmUzMTJlMzMzNU1zeTJYaFBNblRnaVk2V2ZPUnAvNndvOVh5M29jSVRmbENadXp5UEpBcGs9');

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {

  const loadingElement = document.querySelector("body");
  platformBrowserDynamic().bootstrapModule(AppModule).then(() => setTimeout(() => {
    loadingElement.classList.add('fuse-splash-screen-hidden');
    AOS.init({
      duration: 900,
    });
  }, 800
  ))
    .catch(err => console.error(err));
});
