import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './core/services/local-Storage';
import * as myGlobals from 'src/app/core/services/globals';
import { AuthServices } from 'src/app/core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { EmailComponent } from './main/pages/supporting-components/email/email.component';
import { ChatService } from './core/services/chat.service';
import { BehaviorsService } from './core/services/behaviors.service';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { UserDataService } from './core/services/user-data.service';
import { After10SecDialogComponent } from './layout/common/after-10sec-popup/after-10sec-popup.component';
import { AuthService } from './core/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  userDetail: any;
  params = {};
  dialogRef: any;
  popupformstatus: boolean = false;
  isLogin: boolean = false;
  dialogRefSubscription: Subscription;

  /**
   * Constructor
   */
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private localService: LocalStorageService,
    private authServices: AuthServices,
    private dialog: MatDialog,
    private chatService: ChatService,
    private _behaviorsService: BehaviorsService,
    private _userDataService: UserDataService,
    private ccService: NgcCookieConsentService,
    private _authService: AuthService,
    private _matDialog: MatDialog,

  ) {
    this.popupformstatus = this.localService.get(myGlobals.STORAGE_KEYS.POPUPFORM);
    this._authService.getIsLogin().subscribe(result => {
      this.isLogin = result ? result : localStorage.getItem('WIZHOB_TOKEN') ? true : false;
    });
   
  }

  ngOnInit() {
    let userNodeId = this.localService.get(myGlobals.STORAGE_KEYS.NODE_USER_ID);
    if (userNodeId) {
      this.chatService.goOnline(userNodeId);
    } else {
      this.chatService.getOnlineArray();
    }
    this.chatService.getOnlineUsers()
      .subscribe(arrdata => {
        let onlineUserList: any = arrdata.filter(e => e !== 'false');
        this._behaviorsService.setOnlineUserList(onlineUserList);
      });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const childRoute = this.getChild(this.activatedRoute);
      childRoute.data.subscribe(data => {
        this.titleService.setTitle(data.title);
        this.metaTagService.updateTag({ property: 'og:url', content: environment.baseUrl + this.router.url }, 'property="og:url"');
        this.metaTagService.updateTag({ property: 'twitter:url', content: environment.baseUrl + this.router.url }, 'name="twitter:url"');


      });
    });
/* 
    this.activatedRoute.data.subscribe(data => {
      this.after10SecDialog();
    }); */

    this.checkUserActive();


    this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (this.localService.get('USER_ID')) {
          let req: any = {
            user_id: this.localService.get('USER_ID'),
            status: (event.status == 'allow') ? 1 : 'null'
          }
          this._userDataService.setcookieConsent(req).subscribe(res => {
            if (event.status == 'allow') {
              this.localService.set('cookie_consent', 1);
              this.ccService.destroy();
            }
          });
        }
      });

  }

  ngAfterViewInit() {

    if (this.localService.get('cookie_consent') == 1 && this.localService.get('USER_ID')) {
      this.ccService.destroy();
      // this.ccService.init(this.ccService.getConfig());
    }

  }

  after10SecDialog() {
    if (!this.popupformstatus && !this.isLogin) {
      setTimeout(() => {

        this.dialogRef = this._matDialog.open(After10SecDialogComponent,{
          ariaLabel:"promotion"
        });
        this.dialogRefSubscription = this.dialogRef.afterClosed().subscribe(() => {
        });

      }, 10000);

    }
  }
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    }
    else {
      return activatedRoute;
    }
  }
  checkUserActive() {

    this.params['user_id'] = this.localService.get(myGlobals.STORAGE_KEYS.USER_ID);
    if (this.params['user_id'] != '') {
      this.authServices.getUserStatus(this.params).subscribe(result => {
        if (result.body.status_code == 201) {
          const dialogRef = this.dialog.open(EmailComponent, {
            ariaLabel:"Email",
            width: '500px',
          });

        }
      });
    }
  }

  ngOnDestroy() {
    // Ensure that subscription is unsubscribed to prevent memory leaks;
    console.log(this.dialogRefSubscription);

    if (this.dialogRefSubscription) {
      this.dialogRefSubscription.unsubscribe();
    }
  }
}
