<div *transloco="let t">
<div class="mat-dialog-header" mat-dialog-title>
    <div class="flex justify-between align-items-center my-2">
        <h2 class="my-0 font-bold text-center">{{t('post-request-dialog.title')}}</h2>
    </div>
</div>
<div mat-dialog-content>
    <form [formGroup]="postReqFrom">

        <div class="flex flex-col gt-sm:flex-row md:w-140">

            <mat-form-field class="flex-auto" appearance="outline">
                <mat-label>{{t('post-request-dialog.RequirementTile')}}</mat-label>
                <input matInput placeholder="Title" formControlName="title">
                <mat-error *ngIf="f.title.invalid  && (f.title.touched || formSubmmit) && f.title.errors?.required">
                    {{t('post-request-dialog.requiredTitle')}}
                    </mat-error>

            </mat-form-field>

            <mat-form-field class="flex-auto" appearance="outline">
                <mat-label>{{t('post-request-dialog.Select_Requirment_Request_Type')}}</mat-label>
                <mat-select placeholder="Select Request Type" formControlName="requestType">
                    <mat-option value="Course with start date">{{t('post-request-dialog.Start_Date')}}</mat-option>
                    <mat-option value="Anytime classes">{{t('post-request-dialog.anytime_classes')}}</mat-option>
                    <mat-option value="Studio near my house">{{t('post-request-dialog.NeedInfo')}}</mat-option>
                    <mat-option value="Looking for tutor">
                        {{t('post-request-dialog.Looking_for_Tutor_in_a_Category')}}
                    </mat-option>
                    <mat-option value="Something Else">
                        {{t('post-request-dialog.Something_Else')}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="f.requestType.invalid  && (f.requestType.touched || formSubmmit) && f.requestType.errors?.required">
                    {{t('post-request-dialog.Request_Type_is_required')}}
                </mat-error>

            </mat-form-field>

            <mat-form-field class="flex-auto" appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select [disabled]="true" placeholder="Select Category" multiple formControlName="category"
                    (click)="openProfessionCatPopup()" disableOptionCentering>
                    <mat-option *ngFor="let prof of categoryList" [value]=" prof.id">
                        {{prof.title}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="(f.category.touched || formSubmmit) && f.category.errors?.required">
                    
                    {{t('post-request-dialog.Category')}}
                </mat-error>

            </mat-form-field>

            <div class="flex items-start w-full m-2" *ngIf="!loggedUserMobileNo">
                <mat-form-field class="w-3/12">
                    <mat-select class="grid" disableOptionCentering formControlName="dialCode">
                        <mat-select-trigger>
                            <img style="width:32px; float: left;" src="{{ selectedPhoneCode?.image }}" />
                            <span class="absolute m-2"> +{{ selectedPhoneCode?.phonecode }}</span>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [formControl]="countryFillter" [placeholderLabel]="'SEARCH...'">
                            </ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let country of conutryCodeList | async" [value]="country">
                            <img style="width:32px; float: left; margin-top: 4px;" src="{{country?.image}}" /> &nbsp;
                            <span class="text-gray absolute"> +{{country?.phonecode}}&nbsp; {{country?.name}}</span>
                        </mat-option>
                    </mat-select>

                    <mat-error *ngIf="(f.dialCode.touched || formSubmmit) && f.dialCode.errors?.required">
                        {{t('post-request-dialog.phoneCode')}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="w-9/12">
                    <input type="tel" matInput placeholder="555-555-1234" name="mobile" formControlName="mobileNumber">

                    <mat-error *ngIf="(f.mobileNumber.touched || formSubmmit) && f.mobileNumber.errors?.required">
                        {{t('post-request-dialog.Mobile_number_is_required')}}
                    </mat-error>
                </mat-form-field>


            </div>
            <mat-form-field class="flex-auto" appearance="outline">
                <mat-label>{{t('post-request-dialog.Write_Your_Requirement')}}</mat-label>
                <textarea matInput placeholder="Suggest best time to call" formControlName="requirement"></textarea>
                <mat-error *ngIf="(f.requirement.touched || formSubmmit) && f.requirement.errors?.required">
                    {{t('post-request-dialog.Requirement_is_required')}}
                </mat-error>
            </mat-form-field>

        </div>
    </form>

</div>
<mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="true">{{t('post-request-dialog.close')}}</button>
    <button mat-flat-button color="primary" class="bg-wizhob-primary"
        (click)="loggedUserMobileNo? saveData() : sendOTP()" [disabled]="formSubmmit">{{t('post-request-dialog.send')}}
    </button>
</mat-dialog-actions>
</div>


<!-- // transloco done by Mohit  -->