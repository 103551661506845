<div *transloco="let t" class="p-0">
   
 <div class="mat-dialog-header md:w-100 sm:w-100 " mat-dialog-title>
    <div class="flex justify-between align-items-center ">
        <!-- <h2 class="my-0 font-bold text-center">{{t('post-request-dialog.title')}}</h2> -->
    </div>
 </div>
 <div mat-dialog-content>
    <form [formGroup]="postReqFrom">

        <div class="flex flex-col gt-sm:flex-row">

            <mat-form-field class="flex-auto" appearance="outline">
                <mat-label>{{t('after-10sec-popup.name')}}</mat-label>
                <input matInput placeholder="{{t('after-10sec-popup.namePH')}}" formControlName="title">
                <mat-error *ngIf="f.title.invalid  && (f.title.touched || formSubmmit) && f.title.errors?.required">
                    {{t('after-10sec-popup.namereq')}}
                    </mat-error>

            </mat-form-field>

 

            <div class="flex items-start w-full m-2">
                <mat-form-field class="w-3/12">
                    <mat-select aria-label="flags" class="grid" disableOptionCentering formControlName="dialCode">
                        <mat-select-trigger>
                            <img style="width:32px; float: left;" src="{{ selectedPhoneCode?.image }}" />
                            <span class="absolute m-2"> +{{ selectedPhoneCode?.phonecode }}</span>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [formControl]="countryFillter" [placeholderLabel]="'SEARCH...'">
                            </ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let country of conutryCodeList | async" [value]="country">
                            <img style="width:32px; float: left; margin-top: 4px;" src="{{country?.image}}" /> &nbsp;
                            <span class="text-gray absolute"> +{{country?.phonecode}}&nbsp; {{country?.name}}</span>
                        </mat-option>
                    </mat-select>

                    <mat-error *ngIf="(f.dialCode.touched || formSubmmit) && f.dialCode.errors?.required">
                        {{t('after-10sec-popup.phoneCode')}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="w-9/12">
                    <input type="tel" matInput placeholder="555-555-1234" name="mobile" formControlName="mobileNumber">

                    <mat-error *ngIf="(f.mobileNumber.touched ||formSubmmit) || f.mobileNumber.errors?.pattern || f.mobileNumber.errors?.required">
                        {{t('after-10sec-popup.Mobile_number_is_required')}}
                    </mat-error>
                </mat-form-field>


            </div>
            <mat-form-field class="flex-auto" appearance="outline">
                <mat-label>{{t('after-10sec-popup.email')}}</mat-label>
                <input matInput placeholder="{{t('after-10sec-popup.emailPH')}}" formControlName="email">
                <mat-error *ngIf="f.email.invalid  && f.email.touched && f.email.errors?.email">
                    {{t('after-10sec-popup.emailreq')}}
                    </mat-error>

            </mat-form-field>
     

        </div>
    </form>

</div>
<mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="true">{{t('after-10sec-popup.close')}}</button>
    <button mat-flat-button color="primary" class="bg-wizhob-primary w-1/2"
        (click)="sendOTP()">{{t('after-10sec-popup.send')}}
    </button>
</mat-dialog-actions>
</div>




<!-- // transloco done by Mohit  -->