<!-- Button -->
<div [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img alt="user profile image" class="cursor-pointer w-10 h-10 border-gray-400 border-2 rounded-full"
            *ngIf="showAvatar || user?.avatar" [defaultImage]="'assets/img/default_profile.webp'"
            [lazyLoad]="user?.avatar" [useSrcset]="true">
        <mat-icon *ngIf="!showAvatar && !user?.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full" [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user.status === 'online',
                          'bg-amber-500': user.status === 'away',
                          'bg-red-500': user.status === 'busy',
                          'bg-gray-400': user.status === 'not-visible'}"></span>
    </span>
</div>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{user?.usersrtname}}</span>
            <span class="mt-1.5 text-sm font-medium">{{user?.mobile}}</span>

        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>

    <div *ngIf="user.usertype=='3'">
        <button mat-menu-item (click)="redirectPage('dashboard')">
            <mat-icon [svgIcon]="'heroicons_solid:view-grid'"></mat-icon>
            <span>Dashboard</span>
        </button>
        <button mat-menu-item (click)="redirectPage('profile')">
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>Edit Profile</span>
        </button>

    </div>

    <div *ngIf="user.usertype=='2'">
        <button mat-menu-item (click)="redirectPage('expertdashboard')">
            <mat-icon [svgIcon]="'heroicons_solid:view-grid'"></mat-icon>
            <span>Dashboard</span>
        </button>
        <button mat-menu-item (click)="redirectPage('expert-stepper')">
            <mat-icon [svgIcon]="'heroicons_outline:adjustments'"></mat-icon>
            <span>Edit Extra Details</span>
        </button>
        <button mat-menu-item (click)="redirectPage('profile')">
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>Edit Profile</span>
        </button>
        <button mat-menu-item (click)="redirectPage('my-profile')">
            <mat-icon [svgIcon]="'heroicons_outline:academic-cap'"></mat-icon>
            <span>My Public Profile</span>
        </button>

    </div>
    <div *ngIf="user.usertype=='4'">
        <button mat-menu-item (click)="redirectPage('studiodashboard')">
            <mat-icon [svgIcon]="'heroicons_solid:view-grid'"></mat-icon>
            <span>Studio Dashboard</span>
        </button>
        <button mat-menu-item (click)="redirectPage('studio-stepper')">
            <mat-icon [svgIcon]="'heroicons_outline:adjustments'"></mat-icon>
            <span>Edit Extra Details</span>
        </button>
        <button mat-menu-item (click)="redirectPage('profile')">
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>Edit Studio Profile</span>
        </button>
        <button mat-menu-item (click)="redirectPage('public-profile')">
            <mat-icon [svgIcon]="'heroicons_outline:academic-cap'"></mat-icon>
            <span>Studio Public Profile</span>
        </button>

    </div>


    <button mat-menu-item (click)="signOutUser()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sign out</span>
    </button>

</mat-menu>