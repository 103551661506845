import { Injectable , Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { AuthServices } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorServiceService implements HttpInterceptor{

  constructor(private injector: Injector) { }

  intercept( req, next) {
    let authService = this.injector.get(AuthServices);

    let tokenizedReq = req.clone({
      setHeaders:{
         Authorization: `Bearer ${authService.getToken() } `
      }
    })
    return next.handle(tokenizedReq);
 }

}
