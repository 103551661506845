import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from 'src/@fuse';
import { FuseConfigModule } from 'src/@fuse/services/config';
import { FuseMockApiModule } from 'src/@fuse/lib/mock-api';
import { CoreModule } from 'src/app/core/core.module';
import { appConfig } from 'src/app/core/config/app.config';
import { mockApiServices } from 'src/app/mock-api';
import { LayoutModule } from 'src/app/layout/layout.module';
import { AppComponent } from 'src/app/app.component';
import { appRoutes } from 'src/app/app.routing';
import { ToastrModule } from 'ngx-toastr';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorServiceService } from './core/helper/interceptor-service.service';
import { ErrorInterceptor } from './core/helper/error-interceptor';
import { MatNativeDateModule } from '@angular/material/core';

// import { MdePopoverModule } from '@material-extended/mde';
import { RatingModule } from 'ng-starrating';
import { FooterModule } from './layout/common/footer/footer.module';
import { TranslocoRootModule } from './transloco-root.module'
import { TranslocoModule } from '@ngneat/transloco';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

const config: SocketIoConfig = {
    url: environment.NODE_URLS_ENDPOINT + 'api/chatRoomList',
    options: { transports: ['websocket'] }
};

const routerConfig: ExtraOptions = {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',

};

const cookieConfig: NgcCookieConsentConfig = {
    "cookie": {
        "domain": environment.COOKIE_CONSENT_DOMAIN
    },
    "position": "bottom",
    "theme": "classic",
    "palette": {
        "popup": {
            "background": "#000000",
            "text": "#ffffff",
            "link": "#ffffff"
        },
        "button": {
            "background": "#f1d600",
            "text": "#000000",
            "border": "transparent"
        }
    },
    "type": "opt-out",
    "content": {
        "message": "This website uses cookies to ensure you get the best experience on our website.",
        "dismiss": "Got it!",
        "deny": "Deny",
        "link": "Learn more",
        "href": "https://wizhob.com/privacy",
        "policy": "Cookie Policy"
    }
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        BrowserTransferStateModule,
        FormsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        TransferHttpCacheModule,
        SocketIoModule.forRoot(config),
        RouterModule.forRoot(appRoutes, routerConfig),
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        ToastrModule.forRoot(),
        TranslocoModule,
        // MdePopoverModule,
        RatingModule,
        FooterModule,
        TranslocoRootModule,
        LazyLoadImageModule,
        NgcCookieConsentModule.forRoot(cookieConfig),

        // ServiceWorkerModule.register('ngsw-worker.js', {
        //     enabled: environment.production,
        //     // Register the ServiceWorker as soon as the application is stable
        //     // or after 30 seconds (whichever comes first).
        //     registrationStrategy: 'registerWhenStable:30000'
        // }),

    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorServiceService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
    ]
})
export class AppModule {
}
