import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorageService } from './local-Storage';
import * as myGlobals from './globals';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  authToken: String = '';
  distributorId: String = '';

  constructor(private http: HttpClient,
    private localservice: LocalStorageService,
    private router: Router) { }

  /**
   * @function getRequest if token is available then it sends request with header otherwise not
   * @description making headers
   */
  getRequest() {
    const authToken = this.localservice.get(myGlobals.STORAGE_KEYS.TOKEN_KEY);
    let httpRequest = {};
    if (authToken) { 
      httpRequest = {
        observe: 'response',
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `bearer ${authToken}`,
        })
      };
    } else {  
      httpRequest = {
        observe: 'response',
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
      };
    }
    return httpRequest;
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  post(path: string, body: Object = {}): Observable<any> {
    const request = this.getRequest();
    return this.http.post(path, body, request)
      .pipe(catchError(this.formatErrors));
  }

  get(path: string): Observable<any> {
    const request = this.getRequest();
    return this.http.get(path, request)
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    const request = this.getRequest();
    return this.http.put(path, body, request)
      .pipe(catchError(this.formatErrors));
  }

  delete(path: string, body: Object = {}): Observable<any> {
    const request = this.getRequest();
    return this.http.delete(path, request)
      .pipe(catchError(this.formatErrors));
  }


  uploadFile(path: string, files, type, value) {
    var uploadData = new FormData();
    uploadData.append('img', files, value);
    //uploadData.append('filetype', type);
    // const config = {
    //   headers: new HttpHeaders({
    //     'Authorization': `bearer ${this.localStorage.get(myGlobals.STORAGE_KEYS.TOKEN_KEY)}`,
    //   })
    // };
    return this.http.post(path, uploadData)
      .pipe(catchError(this.formatErrors));
  }

}
