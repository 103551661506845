import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthServices } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-verify-otp-dialog',
  templateUrl: './verify-otp-dialog.component.html',
  styleUrls: ['./verify-otp-dialog.component.scss']
})
export class VerifyOtpDialogComponent implements OnInit {
  verifyOtpForm: UntypedFormGroup;

  constructor(
    public _matDialogRef: MatDialogRef<VerifyOtpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private authServices: AuthServices,
  ) {

  }

  ngOnInit(): void {
    this.verifyOtpForm = this._formBuilder.group({
      otp: ['', Validators.compose([Validators.required])],
    });

  }

  verifyOTP() {

    if (!this.verifyOtpForm.valid) {
      this.toastr.error('OTP is required');
      return
    }

    this.authServices.verifyOtp({
      'mobile': this.data.mobile,
      'otp': this.verifyOtpForm.value.otp,
      'type': 'post_a_requirement'
    })
      .subscribe((res) => {
        let resData = res.body;
        if (resData.status == 200) {
          this.toastr.success(resData.message);
          this._matDialogRef.close(true);
        } else {
          this.toastr.error(resData.message);
        }
      },
        (error) => {
          this.toastr.error('OTP not Verified');
        });
  }

}
