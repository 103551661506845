/* tslint:disable:max-line-length */
import { FuseNavigationItem } from 'src/@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'recordings',
        title: 'Recordings',
        type: 'basic',
        link: '/recordings',
    },
    {
        id: 'courses',
        title: 'Courses',
        type: 'basic',
        link: '/courses',
    },

   /*  {
        id: 'studios',
        title: 'Studios',
        type: 'basic',
        link: '/studios'
    }, */

    {
        id: 'tutors',
        title: 'Experts',
        type: 'basic',
        link: '/experts'
    },

    {
        id: 'teach',
        title: 'Tutor Login',
        type: 'basic',
        link: '/expert-register'
    },
    {
        id: 'chats',
        title: 'Chat',
        type: 'basic',
        icon: 'heroicons_solid:chat-alt-2',
        link: '/chat'
    },
    {
        id: 'login',
        title: 'Student Login',
        type: 'basic',
        link: '/login'
    },
    {
        id: 'divider',
        type: 'divider',
    },
    {
        id: 'studiodashboard',
        title: 'Studio Dashboard',
        type: 'basic',
        icon: 'heroicons_solid:view-grid',
        link: '/studiodashboard'
    },
    {
        id: 'expertdashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_solid:view-grid',
        link: '/expertdashboard'
    },
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_solid:view-grid',
        link: '/dashboard'
    },
    {
        id: 'editextradetails',
        title: 'Edit Extra Details',
        type: 'basic',
        icon: 'heroicons_outline:adjustments',
        link: '/expert-stepper'
    },
    {
        id: 'editextradetailstudio',
        title: 'Edit Extra Details',
        type: 'basic',
        icon: 'heroicons_outline:adjustments',
        link: '/studio-stepper'
    },

    {
        id: 'profile',
        title: 'Edit Profile',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/profile'
    },

    {
        id: 'publicprofile',
        title: 'My Public Profile',
        type: 'basic',
        icon: 'heroicons_outline:academic-cap',
        link: '/my-profile'
    },
    {
        id: 'sign-out',
        title: 'Sign out',
        type: 'basic',
        icon: 'heroicons_solid:logout'
    }
];

export const userPanelNavigationL: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_solid:view-grid',
        link: '/dashboard'
    }, {
        id: 'my-interest',
        title: 'My Interest',
        type: 'basic',
        icon: 'heroicons_solid:academic-cap',
        link: '/my-interest'
    },
    {
        id: 'my-booking',
        title: 'Bookings',
        type: 'basic',
        icon: 'heroicons_solid:clipboard-list',
        link: '/user-booking'
    }, {
        id: 'my-attendance',
        title: 'Attendance',
        type: 'basic',
        icon: 'heroicons_solid:calendar',
        link: '/my-attendance'
    },
    {
        id: 'my-pass',
        title: 'My WizPass Cards',
        type: 'basic',
        icon: 'heroicons_solid:ticket',
        link: '/my-pass'
    },

    {
        id: 'chat',
        title: 'Chat',
        type: 'basic',
        icon: 'heroicons_solid:chat',
        link: '/chat'
    },

];

export const expertPanelNavigationL: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_solid:view-grid',
        link: '/expertdashboard'
    },
    {
        id: 'course-list',
        title: 'Courses | Classes',
        type: 'basic',
        icon: 'heroicons_solid:academic-cap',
        link: '/course-list',
        tourGuideId:'app-home'
    },
  /*   {
        id: 'batches-list',
        title: 'Batches | Packages',
        type: 'basic',
        icon: 'heroicons_solid:clipboard-list',
        link: '/batches-list'
    },  */{
        id: 'expert-booking',
        title: 'My Bookings',
        type: 'basic',
        icon: 'heroicons_solid:tag',
        link: '/expert-booking'
    }, {
        id: 'attendance',
        title: 'Manage Attendance',
        type: 'basic',
        icon: 'heroicons_solid:calendar',
        link: '/expert-attendance'
    },
    {
        id: 'earnings-statement',
        title: 'Earnings Statement',
        type: 'basic',
        icon: 'payments',
        link: '/earnings'
    },
    {
        id: 'session-recording',
        title: 'Session Recording',
        type: 'basic',
        icon: 'heroicons_solid:video-camera',
        link: '/session-recording'
    },
    {
        id: 'chat',
        title: 'Chat',
        type: 'basic',
        icon: 'heroicons_solid:chat',
        link: '/chat'
    }
];

export const studioPanelNavigationL: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Studio Dashboard',
        type: 'basic',
        icon: 'heroicons_solid:view-grid',
        link: '/studiodashboard'
    },
    {
        id: 'course-list',
        title: 'Studio Courses | Classes',
        type: 'basic',
        icon: 'heroicons_solid:academic-cap',
        link: '/course-list'
    },
  /*   {
        id: 'batches-list',
        title: 'Batches | Packages',
        type: 'basic',
        icon: 'heroicons_solid:clipboard-list',
        link: '/batches-list'
    },  */{
        id: 'expert-booking',
        title: 'Studio Bookings',
        type: 'basic',
        icon: 'heroicons_solid:tag',
        link: '/expert-booking'
    }, {
        id: 'attendance',
        title: 'Manage Attendance',
        type: 'basic',
        icon: 'heroicons_solid:calendar',
        link: '/expert-attendance'
    },
    {
        id: 'chat',
        title: 'Chat',
        type: 'basic',
        icon: 'heroicons_solid:chat',
        link: '/chat'
    },
    {
        id: 'wizzpass',
        title: 'My WizPass Cards',
        type: 'basic',
        icon: 'heroicons_solid:ticket',
        link: '/wizpass/connect'
    },
];