import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { AuthServices } from 'src/app/core/services/auth.service';
import { UserDataService } from 'src/app/core/services/user-data.service';
import { VerifyOtpDialogComponent } from '../verify-otp-dialog/verify-otp-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/core/services/category.service';
import { LocalStorageService } from 'src/app/core/services/local-Storage';
import * as myGlobals from 'src/app/core/services/globals';

@Component({
  selector: 'app-post-request-dialog',
  templateUrl: './after-10sec-popup.component.html',
  styleUrls: ['./after-10sec-popup.component.scss']
})
export class After10SecDialogComponent implements OnInit {
  conutryCodeList: any;
  selectedPhoneCode: any = [];
  selectedCountryISO = 'IN';
  countryFillter = new FormControl();
  countries: any;
  currentuserdata: any;
  postReqFrom: FormGroup;
  categoryList = [];
  formSubmmit = false;
  loggedUserMobileNo: any = '';
  popupformstatus=false;
  constructor(
    public _matDialogRef: MatDialogRef<After10SecDialogComponent>,

    private userDataService: UserDataService,
    private authServices: AuthServices,
    private _formBuilder: FormBuilder,
    private _matDialog: MatDialog,
    private toastr: ToastrService,
    private _categoryService: CategoryService,
    private _userDataService: UserDataService,
    private _localStorageService: LocalStorageService,


  ) {
    this.loggedUserMobileNo = this._localStorageService.get('MOBILE') || '';
    this.selectedPhoneCode.phonecode = this._localStorageService.get('PHONECODE') || '';
    if (!this.loggedUserMobileNo) {
      this.getUserLocationData();
    }
   
  }

  ngOnInit(): void {
    this.postReqFrom = this._formBuilder.group({
      title: ['', Validators.compose([Validators.required])],
      email: ['', [Validators.email]],
      dialCode: [this.selectedPhoneCode?.phonecode, Validators.compose([Validators.required])],
      mobileNumber: ['', Validators.compose([Validators.required,Validators.pattern("^[0-9]*$")])],
    });
  }

  get f() {
    return this.postReqFrom.controls;
  }
  getUserLocationData(): void {
    this.authServices.userLocationData().subscribe((responseBody) => {
      this.currentuserdata = responseBody;
      this.selectedCountryISO = this.currentuserdata['country_code'];
      this.getCountries();
    });
  }
  getCountries(): void {
    this.userDataService.getCounrty().subscribe((res) => {
      this.countries = res.body.data;
      this.selectedPhoneCode = this.countries.find(c => c.iso === this.selectedCountryISO);
      this.postReqFrom.get('dialCode').setValue(this.selectedPhoneCode);
      this.conutryCodeList = this.countryFillter.valueChanges.pipe(
        startWith(''),
        map(name => (name ? this.countries.filter(option => option.name.toLowerCase().includes(name.toLowerCase())) : this.countries.slice())),

      );
    });
  }




  sendOTP() {
    this.formSubmmit = true;

    if (!this.postReqFrom.valid) {
      this.toastr.error('Enter Required Data');
      this.postReqFrom.markAllAsTouched();
      return;
    }
    let reqData = {
      'mobile': this.postReqFrom.value.mobileNumber.replace(/\s/g, '').replace(/-/g, ''),
      'phonecode': this.postReqFrom.value.dialCode.phonecode
    };
    this.authServices.otpOnMobile(reqData).subscribe((data) => {

      const dialogRef = this._matDialog.open(VerifyOtpDialogComponent, 
        {  ariaLabel:"Verify OTP",
          data: reqData });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.saveData();
        }
        this.formSubmmit = false;
      });
    },
      (_error) => {
        this.toastr.error('OTP Failed');
      });
  }
  saveData() {
    this.formSubmmit = true;

    if (!this.postReqFrom.valid) {
      this.toastr.error('Enter Required Data');
      this.postReqFrom.markAllAsTouched();
      return;
    }
    let reqData = this.postReqFrom.value;
    reqData.dialCode = this.selectedPhoneCode.phonecode;
    reqData.requestType = "Discount-Code";
    reqData.requirement = "10 Second Form Filled";
    
    this._userDataService.postARequirement(this.postReqFrom.value).subscribe((res) => {
      let responseData = res.body;
      if (responseData.status == 200) {
        this.toastr.success(responseData.message);
        this._localStorageService.set(myGlobals.STORAGE_KEYS.POPUPFORM, true);
        this._matDialogRef.close(true);
      } else {
        this.toastr.error(responseData.message);
      }
    });
  }
}
