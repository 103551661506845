import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Urls from '../utility/Urls';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserDataService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private apiService: ApiService,
    private router: Router, private http: HttpClient) { }

  getUserData(user: any): Observable<any> {
    return this.http.post(environment.API_URLS_ENDPOINT + "getuserdetails", user, this.httpOptions);
  }

  updateUserData(updatedUserData: any): Observable<any> {
    return this.http.post(environment.API_URLS_ENDPOINT + "updateuser", updatedUserData);
  }

  getUserInterest(user: any): Observable<any> {
    return this.http.post(environment.API_URLS_ENDPOINT + "getuserinterest", user, this.httpOptions);
  }
  saveUserInterest(params): Observable<any> {
    return this.http.post(environment.API_URLS_ENDPOINT + "saveuserinterest", params, this.httpOptions);
  }

  checkUserExists(data) {
    return this.apiService.post(Urls.ServiceEnum.stripegetproduct, data);
  }

  verifyUser(token: any): Observable<any> {
    return this.http.get(environment.API_URLS_ENDPOINT + "register/confirm/" + token, this.httpOptions);
  }

  getCallInfo(data) {
    return this.apiService.post(Urls.ServiceEnum.callinginfo, data);
  }

  getClassType() {
    return this.apiService.get(Urls.ServiceEnum.getclasstype);
  }

  getClassLocation() {
    return this.apiService.get(Urls.ServiceEnum.getclasslocation);
  }
  getTutorSubject() {
    return this.apiService.get(Urls.ServiceEnum.gettutorsubject);
  }
  getTutorSubjectId(value) {
    return this.apiService.post(Urls.ServiceEnum.gettutorsubjectid, value);
  }

  getCounrty() {
    return this.apiService.get(Urls.ServiceEnum.getcountry);
  }
  tutorSubjectByName(value) {
    return this.apiService.post(Urls.ServiceEnum.tutorsubjectbyname, value);
  }

  sendMailToTutor(params) {
    return this.apiService.post(Urls.ServiceEnum.sendMailToTutor, params);
  }

  allbatchTutorSchedule(params) {
    return this.apiService.post(Urls.ServiceEnum.batchTutorSchedule, params);
  }

  getStudentAppointments(params) {
    return this.apiService.post(Urls.ServiceEnum.getstudentappointments, params);
  }

  setcookieConsent(param) {
    return this.apiService.post(Urls.ServiceEnum.cookieConsent, param);
  }

  postARequirement(param) {
    return this.apiService.post(Urls.ServiceEnum.sendRequirement, param);
  }

  getMembershipBooking(param = {}) {
    return this.apiService.post(Urls.ServiceEnum.getMembershipBooking, param);
  }

  getUserWizpass(param = {}) {
    return this.apiService.post(Urls.ServiceEnum.getUserWizpass, param);
  }
  wizzPassRedeem(param = {}) {
    return this.apiService.post(Urls.ServiceEnum.wizzPassRedeem, param);
  }

  getWizzPassRedeemHis(param) {
    return this.apiService.post(Urls.ServiceEnum.getWizzPassRedeemHis, param);
  }
}
