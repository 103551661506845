import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from 'src/app/shared/shared.module';

import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    SharedModule,
    RouterModule
      ],
  exports: [
    FooterComponent
  ]
})
export class FooterModule { }
