import { Injectable } from '@angular/core';
import * as Urls from '../utility/Urls';
import { ApiService } from './api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private apiService: ApiService,
    private router: Router) { }
  

  getAllCategory(params) {   
    return this.apiService.post(Urls.ServiceEnum.getvendorcat, params);
  }

  getAllCources(params){
  	 return this.apiService.post(Urls.ServiceEnum.getallcourses, params);
  }

  getAllSubCat(params){
    return this.apiService.post(Urls.ServiceEnum.getallsubcat, params);
  }

  getSubCatData(params){
    return this.apiService.post(Urls.ServiceEnum.getsubcatdata, params);
  }

  getLevel(){
    return this.apiService.get(Urls.ServiceEnum.getlevels);
  }

  getCategoryIdFromName(params){
    return this.apiService.post(Urls.ServiceEnum.getCategoryIdFromName, params);
  }

  getTestimonial(params){
    return this.apiService.post(Urls.ServiceEnum.getTestimonial,params);
  }

}
