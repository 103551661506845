import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as Urls from '../utility/Urls';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { io } from 'socket.io-client';


@Injectable({
    providedIn: 'root',
})
export class ChatService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    baseurl: any = environment.NODE_URLS_ENDPOINT;
    socket: any;
    socketmessage: any;
    socketbaseurl: any
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private http: HttpClient,
        private apiService: ApiService) {


        if (isPlatformBrowser(this.platformId)) { // check if this is runing in browser
            this.socketbaseurl = io(this.baseurl, { forceNew: true });
            this.socket = io(this.baseurl + '/api/chatRoomList', { forceNew: true });
            this.socketmessage = io(this.baseurl + '/api/message', { forceNew: true });
        }
    }

    sendSocketTest(id: string) {
        this.socket.emit('random', id);
    }

    fetchAllMessages(chatId) {
        return this.apiService.post(Urls.ServiceEnum.fetchAllMessages, { chat_id: chatId });
    }
    sendMessage(message) {
        this.socketmessage.emit('new_message', message);
    }

    joinChat(message) {
        this.socketmessage.emit('joinChat', message);
    }

    getOnlineArray() {
        this.socketbaseurl.emit('getOnlineArray');
    }

    goOnline(id: string) {
        this.socketbaseurl.emit('goOnline', id);
    }
    goOffline(id: string) {
        this.socketbaseurl.emit('goOffline', id);
    }

    getOnlineUsers() {
        let observable = new Observable<any>(observer => {
            this.socket.on('onOnlineUserListUpdate', (data) => {
                observer.next(data);
            });
        });
        return observable;
    }

    onlineUsers(id: string) {
        this.socket.emit('onlineusers', id); // emitting event to node
    }

    emitAllUsersList(id: string) {
        this.socket.emit('getConversionsList', id); // emitting event to node
    }


    getAllUsersList() {
        let observable = new Observable<any>(observer => {
            this.socket.on('ConversionsListReady', (data) => {
                observer.next(data);
            });
        });
        return observable;
    }

    updateChatRoomList(id: string) {
        let observable = new Observable<{ id: string, status: boolean }>(observer => {
            this.socket.on('updateChatRoomList', (data) => {
                this.socket.emit('getConversionsList', id);
            });
        });
        return observable;
    }

    getConversionsList(id: string) {
        this.socket.emit('getConversionsList', id); // emitting event to node
    }

    onlineUsersStatus() {
        let observable = new Observable<{ id: string, status: boolean }>(observer => {
            this.socket.on('ConversionsListReady', (data) => {
                observer.next(data);
            });
        });
        return observable;
    }

    public getMessages = () => {
        return Observable.create((observer) => {
            this.socketmessage.on('msgReceive', (message) => {
                observer.next(message);
            });
        });
    }

    getMessageslist() {
        let observable = new Observable<any>(observer => {
            this.socketmessage.on('msgReceive', (data) => {
                observer.next(data);
            });
        });
        return observable;
    }

    joinVideoCall(message) {
        this.socketmessage.emit('joinVideoCall', message);
    }

    joinAudioCall(message) {
        this.socketmessage.emit('joinAudioCall', message);
    }

}