import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/core/user/user.service';
import { AuthServices } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-Storage';
import * as myGlobals from 'src/app/core/services/globals';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BehaviorsService } from 'src/app/core/services/behaviors.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = false;
    @Output() signOut: EventEmitter<any> = new EventEmitter();

    user: any = [];
    // user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private authservice: AuthServices,
        private localService: LocalStorageService,
        private _authService: AuthService,
        private _behaviorsService: BehaviorsService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._authService.check().subscribe(result => {
            this.user.usersrtname = this.localService.get('loggedUser');
            this.user.usertype = this.localService.get('usertype');
            this.user.avatar = this.localService.get('image');
            this.user.nodeid = this.localService.get(myGlobals.STORAGE_KEYS.NODE_USER_ID);
            this.user.mobile = this.localService.get('MOBILE');
            this.user.phonecode = this.localService.get('phonecode');
            this._behaviorsService.onlineUserList.subscribe(res => {
                this.user.status = (res.includes(this.user.nodeid)) ? 'online' : '';
                this._changeDetectorRef.markForCheck();
            })
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status
        }).subscribe();
    }

    /**
     * Sign out
     */
    signOutUser(): void {
        this.signOut.emit();
    }
    redirectPage(route) {
        this._router.navigate([route]);
    }

}
