<!-- redesign the footer -->


<div class="" *transloco="let t">
    <div class="mat-tab-body-content ng-tns-c151-194 ng-trigger ng-trigger-translateTab">
<div class="grid grid-cols-1 lg:grid-cols-4 sm:grid-cols-2 text-white  gap-6 w-full lg:justify-items-center sm:justify-items-center lg:m-9  py-4 " style="font-size: 16px;">
    <div class="flex flex-col mx-3 flex-auto footerLinkContainer text-justify">
        <span class="footerHeading flex-0 pb-2">
            <a routerLink="/"> <img  src="assets/images/logo/wizhob-white.png" alt="Wizhob Hobbies  Logo" style="width:200px;"></a>
        </span>
       <span class="font-light text-base mb-5"> 
          
        WizHob is your gateway to acquiring life-enriching hobbies. Our platform is designed
        to empower individuals on their journey to hobby mastery. With WizHob, you can explore a wide
        range of hobbies all while receiving expert guidance. Our certified hobby experts are here to provide
        interactive sessions, ensuring you gain valuable insights and become proficient in your chosen
        pursuits. Join WizHob today and embark on a fulfilling journey of hobby exploration and personal
        growth. With flexible joining dates and times of your choice, you have the freedom to pursue your
        favourite hobbies at your own pace.
        
       </span>

    </div>
    
    <div class="flex flex-col mx-3 flex-auto footerLinkContainer">
        <span class="footerHeading flex-0 pb-2">{{t('footer.Company')}}</span>
        <ul>
            <li class="pb-2"><a routerLink="/about" >{{t('footer.about')}}</a></li>

            <li class="pb-2"><a routerLink="/faq">{{t('footer.FAQ')}}</a></li>
            <li class="pb-2"><a routerLink="/privacy">{{t('footer.PrivacyPolicy')}}</a></li>
            <li class="pb-2"><a routerLink="/term-condition">{{t('footer.TermsConditions')}}</a></li>
            <li class="pb-2"><a href="https://blog.wizhob.com"  target="_blank">{{t('footer.Blog')}}</a></li>
            <li class="pb-2"><a routerLink="/contact">{{t('footer.ContactUs')}}</a></li>
        </ul>
    </div>
    <div class="flex flex-col mx-3 flex-auto footerLinkContainer">
        <span class="footerHeading flex-0 pb-2">{{t('footer.Connect')}}</span>             
        <ul>
            <li class="pb-2"><a routerLink="/explore">{{t('footer.explore')}}</a></li>
            <li class="pb-2"><a routerLink="/combos">{{t('footer.combos')}}</a></li>
            <li class="pb-2"><a routerLink="/wizpass">{{t('footer.wizpass')}}</a></li>             
            <li class="pb-2"><a routerLink="/login">{{t('footer.JoinUser')}}</a></li>
            <li class="pb-2"><a routerLink="/expert-register">{{t('footer.Tutor_Registration')}}</a></li>
            <li class="pb-2"><a routerLink="/studio-register">{{t('footer.Studio_Registration')}}</a></li>
        </ul>
    </div>
    <div class="flex flex-col mx-3 flex-auto footerLinkContainer">
        <span class="footerHeading flex-0 pb-2">{{t('footer.Social')}}</span>
        <ul>
            <li class="pb-2"><a href="https://www.facebook.com/wizhob" target="_blank" class="flex items-center">
                <mat-icon svgIcon="feather:facebook"></mat-icon><span class="pl-2">
                        {{t('footer.Facebook')}}</span>
                </a></li>
            <li class="pb-2"><a href="https://twitter.com/wizhob_/" target="_blank" class="flex items-center">
                <mat-icon svgIcon="feather:twitter"></mat-icon><span class="pl-2">
                        {{t('footer.Twitter')}}</span>
                </a></li>
            <li class="pb-2"><a href="https://www.instagram.com/wizhob/" target="_blank" class="flex items-center">
                <mat-icon svgIcon="feather:instagram"></mat-icon><span class="pl-2">
                        {{t('footer.Instagram')}}</span>
                </a></li>
            <li class="pb-2"><a href="https://www.linkedin.com/company/wizhob" target="_blank" class="flex items-center">
                <mat-icon svgIcon="feather:linkedin"></mat-icon><span class="pl-2">
                        {{t('footer.Linkedin')}}</span>
                </a></li>
        </ul>
    </div>
</div>
    </div>
    <div class="sub-text-footer border-t p-2 text-white text-center">
        <p>{{t('footer.Copywrite')}}</p>
    </div>
</div>  
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
