import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseDrawerModule } from 'src/@fuse/components/drawer';
import { LayoutComponent } from 'src/app/layout/layout.component';
import { EmptyLayoutModule } from 'src/app/layout/layouts/empty/empty.module';
import { CenteredLayoutModule } from 'src/app/layout/layouts/horizontal/centered/centered.module';
import { EnterpriseLayoutModule } from 'src/app/layout/layouts/horizontal/enterprise/enterprise.module';
import { MaterialLayoutModule } from 'src/app/layout/layouts/horizontal/material/material.module';
import { ModernLayoutModule } from 'src/app/layout/layouts/horizontal/modern/modern.module';
import { ClassicLayoutModule } from 'src/app/layout/layouts/vertical/classic/classic.module';
import { ClassyLayoutModule } from 'src/app/layout/layouts/vertical/classy/classy.module';
import { CompactLayoutModule } from 'src/app/layout/layouts/vertical/compact/compact.module';
import { DenseLayoutModule } from 'src/app/layout/layouts/vertical/dense/dense.module';
import { FuturisticLayoutModule } from 'src/app/layout/layouts/vertical/futuristic/futuristic.module';
import { ThinLayoutModule } from 'src/app/layout/layouts/vertical/thin/thin.module';
import { SettingsModule } from 'src/app/layout/common/settings/settings.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PostRequestDialogComponent } from './common/post-request-dialog/post-request-dialog.component';
import { After10SecDialogComponent } from './common/after-10sec-popup/after-10sec-popup.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VerifyOtpDialogComponent } from './common/verify-otp-dialog/verify-otp-dialog.component';
// import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
const layoutModules = [
    // Empty
    EmptyLayoutModule,

    // Horizontal navigation
    CenteredLayoutModule,
    EnterpriseLayoutModule,
    MaterialLayoutModule,
    ModernLayoutModule,

    // Vertical navigation
    ClassicLayoutModule,
    ClassyLayoutModule,
    CompactLayoutModule,
    DenseLayoutModule,
    FuturisticLayoutModule,
    ThinLayoutModule,
];

@NgModule({
    declarations: [
        LayoutComponent,
        PostRequestDialogComponent,
        VerifyOtpDialogComponent,
        After10SecDialogComponent
    ],
    imports: [
        MatIconModule,
        MatTooltipModule,
        FuseDrawerModule,
        SharedModule,
        SettingsModule,
        ...layoutModules,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatProgressSpinnerModule,

    ],
    exports: [
        LayoutComponent,
        ...layoutModules
    ]
})
export class LayoutModule {
}
