/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  baseUrl: 'https://wizhob.com',
  production: true,
  brand:"Wizhob",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  //stripe_key: 'pk_test_51IsjYPSBKxYbSFry7JJwnMtoEykU8akmup2Rzs7yL5Ohj5vJj7Hv4UVB0tJ3sLiYsn39FVIMoNjAnCTYuNkSjWQs00tJanTykB',
 stripe_key: 'pk_live_51IsjYPSBKxYbSFryW6sOnEMgfpBECVEle8tkIjdGfvEyC2qVrVniPwedoQTCGOg1e9FMMq45SifpcqvIfDnLY9Hc00PhhhnjDK',
  //API_URLS_ENDPOINT: '/api/v1/',
 // eslint-disable-next-line @typescript-eslint/naming-convention
 API_URLS_ENDPOINT: 'https://api.wizhob.com/api/v1/',
  //NODE_URLS_ENDPOINT: '/api/',
  COOKIE_CONSENT_DOMAIN: "wizhob.com" ,//"beta.wizhob.co.in",  beta.wizhob.com
  NODE_URLS_ENDPOINT: 'https://chat.wizhob.com/',
  APP_ID : '9c1e5802cde245b8b68733bb27fa9632',
  RECORDING_URL : 'https://api.wizhob.com'
};
