<div class="mat-dialog-header" mat-dialog-title>
    <div class="flex justify-between align-items-center my-2">
        <h2 class="my-0 font-bold text-center">{{isVendor ? 'Courses Category ': 'Professions'}} </h2>
        <!--         <mat-icon mat-dialog-close> close</mat-icon>
 -->
    </div>
</div>
<div mat-dialog-content>
    <main class="main-batch-list-custom">
        <div class="container grid w-full">
            <div class="row">
                <div class="iterdiv pb-6  ml-5">

                    <mat-chip-list selectable="true" [multiple]="multiSelect">
                        <div [ngStyle]="{'width': '100%'}" *ngFor="let cat of categoryArr">
                            <div class="headingCat my-2">
                                {{cat.heading}}
                            </div>
                            <mat-chip #c="matChip" *ngFor="let a of cat.data" [value]="a.id"
                                [selected]="professionSetSelected(a.id)" (click)="toggleSelection(c)">
                                {{a.title}}
                            </mat-chip>
                        </div>
                    </mat-chip-list>
                </div>
            </div>

        </div>
    </main>
</div>
<mat-dialog-actions align="end">

    <button mat-flat-button color="primary" class="bg-wizhob-primary"
        [mat-dialog-close]="selectedProfession">Choose</button>
</mat-dialog-actions>