import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { AuthServices } from 'src/app/core/services/auth.service';
import { UserDataService } from 'src/app/core/services/user-data.service';
import { VerifyOtpDialogComponent } from '../verify-otp-dialog/verify-otp-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ProfessionCategoryComponent } from 'src/app/main/pages/supporting-components/profession-category/profession-category.component';
import { CategoryService } from 'src/app/core/services/category.service';
import { LocalStorageService } from 'src/app/core/services/local-Storage';

@Component({
  selector: 'app-post-request-dialog',
  templateUrl: './post-request-dialog.component.html',
  styleUrls: ['./post-request-dialog.component.scss']
})
export class PostRequestDialogComponent implements OnInit {
  conutryCodeList: any;
  selectedPhoneCode: any = [];
  selectedCountryISO = 'IN';
  countryFillter = new UntypedFormControl();
  countries: any;
  currentuserdata: any;
  postReqFrom: UntypedFormGroup;
  categoryList = [];
  formSubmmit = false;
  loggedUserMobileNo: any = '';
  constructor(
    public _matDialogRef: MatDialogRef<PostRequestDialogComponent>,

    private userDataService: UserDataService,
    private authServices: AuthServices,
    private _formBuilder: UntypedFormBuilder,
    private _matDialog: MatDialog,
    private toastr: ToastrService,
    private _categoryService: CategoryService,
    private _userDataService: UserDataService,
    private _localStorageService: LocalStorageService,


  ) {
    this.loggedUserMobileNo = this._localStorageService.get('MOBILE') || '';
    this.selectedPhoneCode.phonecode = this._localStorageService.get('PHONECODE') || '';
    if (!this.loggedUserMobileNo) {
      this.getUserLocationData();
    }
    this.getCategoreies();
  }

  ngOnInit(): void {
    this.postReqFrom = this._formBuilder.group({
      title: ['', Validators.compose([Validators.required])],
      requestType: ['', Validators.compose([Validators.required])],
      category: ['', Validators.compose([Validators.required])],
      dialCode: [this.selectedPhoneCode?.phonecode, Validators.compose([Validators.required])],
      mobileNumber: [this.loggedUserMobileNo, Validators.compose([Validators.required])],
      requirement: ['', Validators.compose([Validators.required])],
    });
  }

  get f() {
    return this.postReqFrom.controls;
  }
  getUserLocationData(): void {
    this.authServices.userLocationData().subscribe((responseBody) => {
      this.currentuserdata = responseBody;
      this.selectedCountryISO = this.currentuserdata['country_code'];
      this.getCountries();
    });
  }
  getCountries(): void {
    this.userDataService.getCounrty().subscribe((res) => {
      this.countries = res.body.data;
      this.selectedPhoneCode = this.countries.find(c => c.iso === this.selectedCountryISO);
      this.postReqFrom.get('dialCode').setValue(this.selectedPhoneCode);
      this.conutryCodeList = this.countryFillter.valueChanges.pipe(
        startWith(''),
        map(name => (name ? this.countries.filter(option => option.name.toLowerCase().includes(name.toLowerCase())) : this.countries.slice())),

      );
    });
  }


  openProfessionCatPopup() {


    const dialogRef = this._matDialog.open(ProfessionCategoryComponent,
      {
        ariaLabel:"Profession Categories",
        width: '80vw',
        data: { selectedProfession: this.postReqFrom.controls.category.value, isMultiSelect: true, isVendor: true }
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.postReqFrom.controls.category.patchValue(result);
      }
    });
  }

  getCategoreies() {
    this._categoryService.getAllCategory({ 'status': '0' }).subscribe((res) => {
      const arr = [];
      res.body.categories.forEach(function (value: any) {
        const ar = value.data;
        ar.forEach(function (el: { id: any; title: any }) {
          arr.push({ 'id': el.id, 'title': el.title });
        });
      });
      this.categoryList = arr;
    });
  }
  sendOTP() {
    this.formSubmmit = true;

    if (!this.postReqFrom.valid) {
      this.toastr.error('Enter Required Data');
      this.postReqFrom.markAllAsTouched();
      return;
    }
    let reqData = {
      'mobile': this.postReqFrom.value.mobileNumber.replace(/\s/g, '').replace(/-/g, ''),
      'phonecode': this.postReqFrom.value.dialCode.phonecode
    };
    this.authServices.otpOnMobile(reqData).subscribe((data) => {

      const dialogRef = this._matDialog.open(VerifyOtpDialogComponent, { data: reqData });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.saveData();
        }
        this.formSubmmit = false;
      });
    },
      (_error) => {
        this.toastr.error('OTP Failed');
      });
  }
  saveData() {
    this.formSubmmit = true;

    if (!this.postReqFrom.valid) {
      this.toastr.error('Enter Required Data');
      this.postReqFrom.markAllAsTouched();
      return;
    }
    let reqData = this.postReqFrom.value;
    reqData.dialCode = this.selectedPhoneCode.phonecode;
    this._userDataService.postARequirement(this.postReqFrom.value).subscribe((res) => {
      let responseData = res.body;
      if (responseData.status == 200) {
        this.toastr.success(responseData.message);
        this._matDialogRef.close(true);
      } else {
        this.toastr.error(responseData.message);
      }
    });
  }
}
