import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthServices } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private auth: AuthServices) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err) {

          // Don't do anything if we're on the login page
          if (err.status === 401 && document.location.href.includes('/login')) {
            return throwError(err);
          }

          // If unauthorized, log user out
          if (err.status === 401) {
            this.auth.logout();
          }

          // this.errorAlert.show(`${err.name} ${err.status}: ${err.statusText}`);
        }

        return throwError(err);
      })
    );
  }
}
